import './assets/css/App.css';
import React from 'react'
import { HomeComponent } from './pages/home/Home';
import NotFoundComponent from './pages/not-found/NotFound';
import { Routes, Route, HashRouter } from 'react-router-dom';
import DocumentacionProveedor from './pages/formulario-datos-proveedor/DocumentacionProveedor';
import FormConfirmarMailComponent from './pages/formulario-datos-proveedor/FormConfirmacionMail';
import FormProveedorComponent from './pages/formulario-datos-proveedor/FormProveedor';
import FormFormaPagoComponent from './pages/formulario-datos-proveedor/FormFormaPago';
import DetalleProveedorComponent from './pages/auth0/admin/DetalleProveedor';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Login from './pages/auth0/Login';
import AdminComponent from './pages/auth0/admin/Admin';
import LogoutButton from './pages/auth0/LogoutButton';
import ImprimirDetalleProveedorComponent from './pages/formulario-datos-proveedor/VistaProveedorPdf/ImprimirDetalleProveedor';
import ListaProveedores from './pages/auth0/admin/ListaProveedores';

const apiKeyRecaptcha = process.env.REACT_APP_API_KEY_PUBLIC_RECAPTCHA_V3;
function App() {
  return (
    <div className="App">
      <GoogleReCaptchaProvider reCaptchaKey={apiKeyRecaptcha}>
        <HashRouter>
          <Routes>
            <Route path='/' element={<HomeComponent />} />
            <Route path='/admin/login' element={<Login />} />
            <Route path='/logout' element={<LogoutButton />} />
            <Route path='/admin' element={<AdminComponent />} />
            <Route path='/admin/proveedores' element={<ListaProveedores />} />
            <Route path='/confirmar' element={<FormConfirmarMailComponent />} />
            <Route path='/registro/proveedor/:id' element={<FormProveedorComponent />} />
            <Route path='/documentacion/proveedor/:id' element={<DocumentacionProveedor />} />
            <Route path='/formapago/proveedor/:id' element={<FormFormaPagoComponent />} />
            <Route path='/proveedor/detalle/:id' element={<DetalleProveedorComponent />} />
            <Route path='/proveedor/detalle/imprimir/:id' element={<ImprimirDetalleProveedorComponent />} />
           
            <Route path='**' element={<NotFoundComponent />} />
          </Routes>
        </HashRouter>
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
