import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

const ModalComponent = (props) => {
  const {
    className,
    dataModal
  } = props;

  const [modal, setModal] = useState(dataModal ? true : false);

  const toggle = () => setModal(!modal);

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody className="text-center">
          {dataModal?.icono === 'danger' &&
            <FontAwesomeIcon style={{ fontSize: '50px', color: '#FF4444' }} icon={faCircleExclamation} />
          }
          <h4 className="mt-3 mb-2">{dataModal?.titulo}</h4>
          <p className="text-muted mb-0">{dataModal?.descripcion}</p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center text-center border border-top-0">
          <Button color="secondary" onClick={toggle}>Aceptar</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default ModalComponent;