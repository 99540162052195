import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    isAuthenticated && (
      <button className="btn btn-danger m-1 btn-lg" onClick={() => logout({ returnTo: process.env.REACT_APP_HOME_PAGE + '/#/admin/login' })}>
        Cerrar sesión
      </button>
    )
  );
};

export default LogoutButton;