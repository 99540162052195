import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import HeaderComponent from '../../shared/Header';


const NotFoundComponent = () => {
    return (
        <Fragment>
            <HeaderComponent />
            <div className="container bg-white">
                <div className="row">
                    <div className="col-12 py-5 text-center">
                        <h1>Página no encontrada </h1>
                        <Link className="btn btn-danger btn-lg m-1" to="/">Volver a Inicio</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NotFoundComponent;