import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderComponent from '../../../shared/Header';
import DataProveedorComponent from '../../formulario-datos-proveedor/DataProveedor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faDownload } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from "@react-pdf/renderer";
import VistaProveedorPdf from '../../formulario-datos-proveedor/VistaProveedorPdf/VistaProveedorPdf';
import FooterComponent from '../../../shared/Footer';
import Loader from '../../../components/Loader';

const endpoint = process.env.REACT_APP_BACKEND_URL;

const DetalleProveedorComponent = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [proveedor, setProveedor] = useState();
    const [listaFormaPagos, setListaFormaPagos] = useState([]);
    const [listaFormaPagosTransferencia, setlistaFormaPagosTransferencia] = useState([]);
    const [listaFormaPagosCheques, setlistaFormaPagosCheques] = useState([]);
    const [listaFormaPagosContado, setlistaFormaPagosContado] = useState([]);
    const [listaDocumentacionProveedor, setListaDocumentacionProveedor] = useState([]);
    const [nombrePdfProveedor, setNombrePdfProveedor] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (+id !== 0) {
            getProveedor(id);
            getFormasPagosDelProveedor(id);
            getDocumentacionDelProveedor(id);
        }
    }, [])

    const getFormasPagosDelProveedor = async (idProveedor) => {
        try {
            const response = await axios.get(`${endpoint}/formapagos/proveedor/${idProveedor}`);
            setListaFormaPagos(response.data?.data);
            setlistaFormaPagosTransferencia(response.data?.data.filter(fp => fp.pago_tipo.toLowerCase().includes('transferencia')));
            setlistaFormaPagosCheques(response.data?.data.filter(fp => fp.pago_tipo.toLowerCase().includes('cheque')));
            setlistaFormaPagosContado(response.data?.data.filter(fp => fp.pago_tipo.toLowerCase().includes('contado')));
        } catch (error) {
            console.error('Error al obtener las formas de pago:', error);
        }
    }

    const getProveedor = async (id) => {
        setLoading(true);
        await axios.get(`${endpoint}/proveedores/${id}`).then(response => {
            let proveedor = response.data.data;
            if (proveedor) {
                setProveedor(prevProveedor => prevProveedor = proveedor);
                setNombrePdfProveedor(prevNombre => prevNombre = `Proveedor${proveedor.cuit}.pdf`);
                setLoading(false);
            }
        }).catch((error) => {
            console.error('error al obtener proveedor:', error);
            setLoading(false);
        });
    }

    const getDocumentacionDelProveedor = async (idProveedor) => {
        const response = await axios.get(`${endpoint}/documentacion/proveedor/${idProveedor}`);
        setListaDocumentacionProveedor((prevLista) => prevLista = response.data.data);
    }
    const descargarTodosDocumentosProveedor = () => {
        if (listaDocumentacionProveedor.length > 0) {
            listaDocumentacionProveedor.forEach(documento => {
                axios({
                    url: `${endpoint}/download/${documento.nombre_archivo}`,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let nombreArchivo = documento.categoria + proveedor.cuit + '.' + documento.nombre_archivo.split('.')[1];
                    link.setAttribute('download', nombreArchivo);
                    document.body.appendChild(link);
                    link.click();
                })
            });
        }
    }

    const descargar = (documento) => {
        axios({
            url: `${endpoint}/download/${documento.nombre_archivo}`,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let nombreArchivo = documento.categoria + proveedor.cuit + '.' + documento.nombre_archivo.split('.')[1];
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
        })
    }

    const tranformarPropiedad = (key) => {
        let nuevaKey = '';
        switch (key.toString().toUpperCase()) {
            case 'created_at':
                return 'FECHA DE ALTA'
            case 'updated_at':
                return 'FECHA DE ACTUALIZACION'
            case 'CONFIRMATED':
                return 'VALIDO SU CORREO'
            case 'CONVENIO_CM':
                return 'CONVENIO MULTILATERAL'
            case 'CERTIFICADO DE NO RETENCION DE IMPUESTOS':
                return 'CERTIFICADO DE NO RETENCIÓN DE IMPUESTOS'
            case 'IIBB':
                return 'CONSTANCIA DE INSC. INGRESOS BRUTOS'
            case 'AFIP':
                return 'CONSTANCIA DE INSC. AFIP'
            case 'INGRESOS_BRUTOS':
                return 'NRO INSCRIPCIÓN INGRESOS BRUTOS';
            default:
                nuevaKey = key.toString().split("_").join(' ');
                nuevaKey = nuevaKey.trim();
                return nuevaKey.toString().toUpperCase();
        }
    }

    const imprimirProveedor = (proveedor) => {
        navigate(`/proveedor/detalle/imprimir/${proveedor.id}`);
    }

    return (
        <Fragment>
            <HeaderComponent isAdmin={true} />
            <div className='container bg-white mb-0 pt-4 pb-2'>
                <div className='px-3 pt-3 text-start border-bottom'>
                    <h1>Proveedor</h1>
                    <p className='text-muted'>Detalle del proveedor</p>
                </div>

                {loading ?
                    <div>
                        <Loader />
                    </div>
                    :
                    <div>
                        <div className="row m-3 d-flex justify-content-start text-start">
                            {proveedor &&
                                <div className="col-sm-12 col-md-12 col-lg-12 my-1">
                                    <div className='my-2'>
                                        <DataProveedorComponent proveedor={proveedor} pais={proveedor.pais} rubroActividad={proveedor.rubro_actividad} mostrarFechas={true} />
                                    </div>

                                </div>
                            }

                            {listaFormaPagos.length > 0 &&
                                <div className="col-sm-12 col-md-12 col-lg-12 my-3 p-0">
                                    <div>
                                        <div className="row m-0 d-flex justify-content-start">
                                            <div className='mt-3 mb-2 text-start border-bottom'>
                                                <h3>Medios de pago</h3>
                                                <p className='text-muted'>Detalle de los medios de pagos.</p>
                                            </div>

                                            {listaFormaPagosContado.length > 0 && <h4 className='text-start'>Contado</h4>}
                                            {listaFormaPagosContado.map((mp, index) =>
                                                <div className="col-sm-12 col-md-12 col-lg-5" key={index}>
                                                    <div className="card my-3 rounded shadow">
                                                        <div className="card-body text-start">
                                                            <div className="row m-0 d-flex justify-content-start">
                                                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                                                    <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {listaFormaPagosTransferencia.length > 0 && <h4 className='text-start'>Transferencias</h4>}
                                            {listaFormaPagosTransferencia.map((mp, index) =>
                                                <div className="col-sm-12 col-md-12 col-lg-6" key={index}>
                                                    <div className="card my-3 rounded shadow">
                                                        <div className="card-body text-start">
                                                            <div className="row m-0 d-flex justify-content-start">
                                                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                                                    <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                                    <p className="card-title"><strong>Titular: </strong>{mp.titular_cuenta}</p>
                                                                    <p className="card-title"><strong>Tipo de Banco:</strong> {mp.banco_tipo}</p>
                                                                    <p className="card-title"> <strong>{mp.banco_tipo === 'TRADICIONAL' ? 'CBU' : 'CBU/CVU'}:</strong> {mp.cbu_cvu}</p>
                                                                    <p className="card-title"><strong>Alias: </strong>{mp.alias}</p>
                                                                </div>
                                                                <div className='col-sm-12 col-md-6 col-lg-6'>

                                                                    <p className="card-title "><strong>Nombre de la Entidad:</strong> {mp.banco_nombre}</p>
                                                                    <p className="card-title"> <strong>Nro Cuenta:</strong> {mp.nro_cuenta}</p>
                                                                    {mp.banco_tipo === 'TRADICIONAL' && mp.cuenta_tipo && <p className="card-title"><strong>Tipo de Cuenta:</strong> {mp.cuenta_tipo}</p>}
                                                                    {mp.banco_tipo === 'TRADICIONAL' && mp.cuenta_tipo && mp.cuenta_tipo.includes('OTRO') && <p className="card-title"><strong>Otro Tipo de Cuenta:</strong> {mp.cuenta_tipo_otro}</p>}
                                                                    <p className="card-title"><strong>CUIL/CUIT: </strong>{mp.cuil_cuit}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {listaFormaPagosCheques.length > 0 && <h4 className='text-start my-2'>Cheques</h4>}

                                            {listaFormaPagosCheques.map((mp, index) =>
                                                <div className="col-sm-12 col-md-6 col-lg-5" key={index}>
                                                    <div className="card my-3 rounded shadow">
                                                        <div className="card-body text-start">
                                                            <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                            <p className="card-title"><strong>A la orden de: </strong>{mp.cheque_orden}</p>
                                                            <p className="card-title"><strong>Concepto: </strong> {mp.cheque_concepto}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='col-sm-12 col-md-12 col-lg-12 my-3 p-0'>
                                <div className='row m-0 d-flex justify-content-start'>
                                    <div className='col-sm-12 col-md-12 col-lg-12 border-bottom'>
                                        <h3>Documentación adjunta</h3>
                                        <p className='text-muted'>Detalle de la documentación</p>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                        {listaDocumentacionProveedor.length === 0 &&
                                            <div className="alert alert-danger">
                                                <p>El proveedor no adjunto ninguna documentación</p>
                                            </div>
                                        }
                                        {listaDocumentacionProveedor.length > 0 &&
                                            <div className="card rounded shadow col-sm-12 col-md-12 col-lg-6 my-3" >
                                                <div className="card-body">
                                                    <ul className="list-group list-group-flush">
                                                        {listaDocumentacionProveedor.map((value, index) =>
                                                            <li key={index} className="list-group-item d-flex justify-content-start m-1 align-items-center">
                                                                <button className='btn btn-danger d-print-none' onClick={() => descargar(value)}>descargar</button>
                                                                <h5 className='card-subtitle mx-2'> {tranformarPropiedad(value.categoria)}</h5>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        {proveedor &&
                            <div className="row m-0 d-flex justify-content-start text-start border-top my-2 p-0">
                                <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                                    {proveedor && <button className='btn btn-primary m-1 btn-lg' onClick={() => imprimirProveedor(proveedor)}> <FontAwesomeIcon icon={faPrint} /> Imprimir</button>}
                                    <PDFDownloadLink
                                        document={<VistaProveedorPdf
                                            proveedor={proveedor} pais={proveedor.pais} mostrarFechas={true}
                                            listaFormaPagos={listaFormaPagos}
                                            listaFormaPagosTransferencia={listaFormaPagosTransferencia}
                                            listaFormaPagosCheques={listaFormaPagosCheques}
                                            listaFormaPagosContado={listaFormaPagosContado}
                                            listaDocumentacionProveedor={listaDocumentacionProveedor}
                                            rubroActividad={proveedor.rubro_actividad}

                                        />}
                                        fileName={nombrePdfProveedor}
                                    >

                                        <button className='btn fondo m-1 btn-lg'><FontAwesomeIcon icon={faDownload} /> Descargar PDF</button>
                                    </PDFDownloadLink>
                                    <button className='btn btn-secondary m-1 btn-lg' onClick={() => descargarTodosDocumentosProveedor()}><FontAwesomeIcon icon={faDownload} /> Descargar todos los documentos adjuntos</button>
                                    <Link className='btn btn-light btn-lg m-1' to="/admin/proveedores">Cancelar</Link>
                                </div>
                            </div>
                        }
                    </div>
                }

            </div>
            <FooterComponent />
        </Fragment>
    )
}

export default DetalleProveedorComponent