import React, { Fragment } from 'react';
import { useState } from 'react';
import DataProveedorComponent from './DataProveedor';

export const VistaPreviaProveedor = (props) => {
    const { proveedor, listaFormasDePago , listaRubrosActividades } = props;
    const [listaFormaPagosTransferencia] = useState(listaFormasDePago.filter(fp => fp.pago_tipo.toLowerCase().includes('transferencia')));
    const [listaFormaPagosCheques] = useState(listaFormasDePago.filter(fp => fp.pago_tipo.toLowerCase().includes('cheque')));
    const [listaFormaPagosContado] = useState(listaFormasDePago.filter(fp => fp.pago_tipo.toLowerCase().includes('contado')));
    const [rubroActividadElegido] = useState(listaRubrosActividades.find(actividad=> actividad.id === +proveedor.rubro_actividad_id));

    return (
        <Fragment>
            {proveedor &&
                <div className="container text-start my-5">
                    <div className='my-3 text-start ps-3 border-bottom'>
                        <h2>Vista previa</h2>
                        <p className='text-muted'>En esta sección podrás visualizar los datos previamente cargados.</p>
                    </div>

                    <div className='my-3 text-start ps-3 border-bottom'>
                        <h4 className='my-3' >Proveedor</h4>
                        <p className='text-muted my-2 pb-2'>Detalle de datos del proveedor</p>
                        <DataProveedorComponent proveedor={proveedor} pais={props.pais} mostrarFechas={false} rubroActividad={rubroActividadElegido} />
                    </div>

                    <div className='my-3 text-start p-1'>
                        {listaFormasDePago.length > 0 ?
                            <div className="my-4">
                                <div className="container p-0">
                                    <div className="row m-0 d-flex justify-content-start    ">
                                        <div className="col-md-12 text-start my-3 border-bottom">
                                            <h3>Formas de pago</h3>
                                            <p className='text-muted my-2'>Detalle de las formas de pago agregadas previamente.</p>
                                        </div>

                                        {listaFormaPagosContado.length > 0 && <h4 className='text-start'>Contado</h4>}

                                        {listaFormaPagosContado.map((mp, index) =>
                                            <div className="col-sm-12 col-md-6 col-lg-6 mb-3" key={mp.id}>
                                                <div className="card rounded shadow">
                                                    <div className="card-body text-start">
                                                        <div className="row m-0 d-flex justify-content-start">
                                                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                                                <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {listaFormaPagosTransferencia.length > 0 && <h4 className='text-start mt-3'>Transferencias</h4>}

                                        {listaFormaPagosTransferencia.map((mp, index) =>
                                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3" key={mp.id}>
                                                <div className="card rounded shadow">
                                                    <div className="card-body text-start">
                                                        <div className="row m-0 d-flex justify-content-start">
                                                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                                                <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                                <p className="card-title"><strong>Titular: </strong>{mp.titular_cuenta}</p>
                                                                <p className="card-title"><strong>Tipo de Banco:</strong> {mp.banco_tipo}</p>
                                                                <p className="card-title "><strong>Nombre de la Entidad:</strong> {mp.banco_nombre}</p>
                                                                <p className="card-title"> <strong>{mp.banco_tipo === 'TRADICIONAL' ? 'CBU' : 'CBU/CVU'}:</strong> {mp.cbu_cvu}</p>

                                                            </div>
                                                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                                                <p className="card-title"><strong>Alias: </strong>{mp.alias}</p>
                                                                <p className="card-title"> <strong>Nro Cuenta:</strong> {mp.nro_cuenta}</p>
                                                                {mp.banco_tipo === 'TRADICIONAL' && <p className="card-title"><strong>Tipo de Cuenta:</strong> {mp.cuenta_tipo}</p>}
                                                                {mp.banco_tipo === 'TRADICIONAL' && mp.cuenta_tipo.includes('OTRO') && <p className="card-title"><strong>Otro Tipo de Cuenta:</strong> {mp.cuenta_tipo_otro}</p>}
                                                                <p className="card-title"><strong>CUIL/CUIT: </strong>{mp.cuil_cuit}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {listaFormaPagosCheques.length > 0 && <h4 className='text-start mt-3'>Cheques</h4>}

                                        {listaFormaPagosCheques.map((mp, index) =>
                                            <div className="col-sm-12 col-md-6 col-lg-6 mb-3" key={mp.id}>
                                                <div className="card rounded shadow">
                                                    <div className="card-body text-start">
                                                        <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                        <p className="card-title"><strong>A la orden de: </strong>{mp.cheque_orden}</p>
                                                        <p className="card-title"><strong>Concepto: </strong> {mp.cheque_concepto}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='alert m-2 alert-warning'>
                                <p className='mb-0'>No tiene ningún medio de pago agregado. Por favor crear un nuevo medio de pago</p>
                            </div> 
                        }
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default VistaPreviaProveedor;
