import React from "react";
import { Fragment } from "react";

const Loader = () => {
  return (
    <Fragment>
       <div className="spinner-border text-center" role="status">
            <span className="visually-hidden">Loading...</span>
       </div>
    </Fragment>
  );
};

export default Loader;