import React from 'react'
import { Fragment } from 'react'

const FooterComponent = () => {
    const version = '1.1';
    const copyright = new Date().getFullYear();
    return (
        <Fragment>
            <div className="container mb-5 mt-0 py-4">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className="mb-0">Versión {version} - &copy;{copyright}</p>
                        <p>Proveedores - Admin</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FooterComponent;