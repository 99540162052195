import axios from "axios";
import { useState } from "react";

const endpoint = process.env.REACT_APP_BACKEND_URL;

export const useForm = (initialForm, validateForm) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [loading] = useState(false);
  const [response] = useState(null);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (e.target.files?.[0]) {
      //es un rchivo
      setForm({
        ...form,
        ['archivo']: e.target.files[0],
        ['nombre']: e.target.files[0].name,
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleBlur = (e) => {
    handleChange(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(validateForm(form));

    const fd = new FormData();
    fd.append('file', form.archivo, form.nombre);

    await axios.post(`${endpoint}/upload`, fd,
      { headers: { 'Content-Type': 'multipart/from-data' } })
      .then(response => {
        console.log('respuesta al subir:', response)
      }).catch(error => {
        console.log('error:', error);
      });

    console.log('respuesta post:', response);

    if (Object.keys(errors).length === 0) {
      alert("Enviando Formulario");
    } else {
      return;
    }
  };

  return {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};