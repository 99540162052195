import React, { Fragment } from 'react'
import HeaderComponent from '../../shared/Header'

import { Link } from 'react-router-dom'

export const HomeComponent = () => {

    return (
        <Fragment>
            <HeaderComponent isAdmin={false}/>
            <div className="my-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="">
                                <div className="card-body">
                                    <div className="row m-0 d-flex justify-content-center text-center">
                                        <div className="col-sm-12 col-md-12 col-lg-12 m-2 d-flex justify-content-center">
                                            <div className="card col-sm-12 col-md-12 col-lg-6 shadow rounded pb-2">
                                                <div className='card-header p-0' style={{ backgroundColor: '#eff2f6'}}>
                                                    <h4 className="card-title py-2 my-2">Registro de Proveedor</h4>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        Para empezar a completar el formulario de Registro de Proveedor haga clic en el botón <strong>"Ingresar"</strong>. En caso de que haya completado el formulario con anterioridad y desee modificar sus datos, haga clic en el enlace que está más abajo.
                                                    </p>
                                                    <Link className="btn btn-info btn-lg mx-1 my-3 text-white" to="/registro/proveedor/0">Ingresar</Link> <br />
                                                    <Link className="my-3" to="/confirmar">Modificar mis datos</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
