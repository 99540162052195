import React, { useState } from 'react';
import { Fragment } from 'react';
import { useForm } from "../../hook/useForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const initialFormFormaPago = {
    id: null,
    proveedor_id: '',
    pago_tipo: '',
    banco_tipo: '',
    titular_cuenta: '',
    cbu_cvu: '',
    alias: '',
    banco_nombre: '',
    nro_cuenta: '',
    cuil_cuit: '',
    cuenta_tipo: '',
    cuenta_tipo_otro: '',
    cheque_orden: '',
    cheque_concepto: ''
};

const FormFormaPagoComponent = (props) => {

    const [listaFormaPagosTransferencia, setlistaFormaPagosTransferencia] = useState(props.listaFP.filter(fp => fp.pago_tipo.toLowerCase().includes('transferencia')));
    const [listaFormaPagosCheques, setlistaFormaPagosCheques] = useState(props.listaFP.filter(fp => fp.pago_tipo.toLowerCase().includes('cheque')));
    const [listaFormaPagosContado, setListaFormaPagosContado] = useState(props.listaFP.filter(fp => fp.pago_tipo.toLowerCase().includes('contado')));

    const { eventEmmiterLista } = props;

    const validationsForm = (form) => {

        let errors = {};
        let regNumeros = /^([0-9])*$/;
        let regCantidadDigitosCbu = /^.{22}$/;
        let regCuitLongitud = /^.{10,11}$/;


        if (!form.pago_tipo.trim()) {
            errors.pago_tipo = "El campo tipo de Pago es requerido";
        } else {
            if (form.pago_tipo.toLowerCase().includes('transferencia')) {
                if (!form.banco_tipo.trim()) {
                    errors.banco_tipo = "El campo tipo de Banco es requerido";
                } else {
                    if (form.banco_tipo.toLowerCase().includes('tradicional')) {
                        if (!form.cuenta_tipo.trim() || form.cuenta_tipo.trim().toLowerCase().includes('seleccionar')) {
                            errors.cuenta_tipo = "El campo tipo de Cuenta es requerido";
                        }
                        if (form.cuenta_tipo.trim().toLowerCase().includes('otro') && !form.cuenta_tipo_otro.trim()) {
                            errors.cuenta_tipo_otro = "El campo otros es requerido";
                        }
                        else if (form.cuenta_tipo.trim().toLowerCase().includes('otro') && form.cuenta_tipo_otro.trim().length < 5) {
                            errors.cuenta_tipo_otro = 'El campo otros debe tener mas caracteres.';
                        }
                        if (!form.cbu_cvu.trim()) {
                            errors.cbu_cvu = "El campo CBU es requerido";
                        } else if (!regNumeros.test(form.cbu_cvu.trim())) {
                            errors.cbu_cvu = 'El campo CBU solo permite numeros';
                        } else if (!regCantidadDigitosCbu.test(form.cbu_cvu.trim())) {
                            errors.cbu_cvu = "El campo CBU debe tener 22 digitos";
                        }
                    } else {
                        if (!form.cbu_cvu.trim()) {
                            errors.cbu_cvu = "El campo CBU/CVU es requerido";
                        } else if (!regNumeros.test(form.cbu_cvu.trim())) {
                            errors.cbu_cvu = 'El campo CBU/CVU solo permite numeros';
                        } else if (!regCantidadDigitosCbu.test(form.cbu_cvu.trim())) {
                            errors.cbu_cvu = "El campo CBU/CVU debe tener 22 digitos";
                        }
                    }
                    if (!form.titular_cuenta.trim()) {
                        errors.titular_cuenta = "El campo Titular de la Cuenta es requerido";
                    }
                    else if (form.titular_cuenta.trim().length < 5) {
                        errors.titular_cuenta = 'El campo Titular de la Cuenta debe tener mas caracteres.';
                    }

                    if (!form.alias.trim()) {
                        errors.alias = "El campo Alias es requerido";
                    }
                    else if (form.alias.trim().length < 5) {
                        errors.alias = 'El campo Alias debe tener mas caracteres.';
                    }
                    if (!form.nro_cuenta.trim()) {
                        errors.nro_cuenta = "El campo Nro de Cuenta es requerido";
                    } else if (!regNumeros.test(form.nro_cuenta.trim())) {
                        errors.nro_cuenta = 'El campo Nro de Cuenta solo permite numeros';
                    }
                    else if (form.nro_cuenta.trim().length < 10) {
                        errors.nro_cuenta = 'El campo Nro de Cuenta debe tener mas digitos';
                    }

                    if (!form.cuil_cuit.trim()) {
                        errors.cuil_cuit = "El campo CUIL/CUIT es requerido";
                    } else if (!regNumeros.test(form.cuil_cuit.trim())) {
                        errors.cuil_cuit = 'El campo CUIL/CUIT solo permite numeros';
                    }
                    else if (!regCuitLongitud.test(form.cuil_cuit.trim())) {
                        errors.cuil_cuit = 'El campo CUIL/CUIT debe tener 11 digitos';
                    }

                    if (!form.banco_nombre.trim() || form.banco_nombre.trim().toLowerCase().includes('seleccionar')) {
                        errors.banco_nombre = "El campo Nombre de la Entidad es requerido";
                    }
                }

            } else {
                if (form.pago_tipo.toLowerCase().includes('cheque')) {
                    if (!form.cheque_orden.trim()) {
                        errors.cheque_orden = "El campo a la Orden es requerido";
                    }
                    else if (form.cheque_orden.trim().length < 5) {
                        errors.cheque_orden = 'El campo a la Orden debe tener mas caracteres.';
                    }
                    if (!form.cheque_concepto.trim()) {
                        errors.cheque_concepto = "El campo Concepto es requerido";
                    }
                    else if (form.cheque_concepto.trim().length < 5) {
                        errors.cheque_concepto = 'El campo Concepto debe tener mas caracteres.';
                    }

                }
            }
        }
        return errors;
    };

    const {
        form,
        handleChange,
        handleBlur
    } = useForm(initialFormFormaPago, validationsForm);

    const [habilitarTipoPago, setHabilitarTipoPago] = useState(false);

    const validarTipoBancoTradicional = () => {
        return form.banco_tipo === 'TRADICIONAL';
    }
    const [listaFormasDePagosAgregadas, setListaFormasDePagosAgregadas] = useState(props.listaFP);
    const [tiposDecuentas] = useState(props.listaTiposCuentas);
    const [bancos, setBancos] = useState(props.listaBancosTradicionales);


    const [errorsForm, setErrorsForm] = useState({});

    const agregarFormaPago = () => {
        setErrorsForm(validationsForm(form));

        if (Object.keys(validationsForm(form)).length === 0) {
            let formEnviar = Object.assign({}, form);
            delete formEnviar.id

            if (form.pago_tipo.toLowerCase() === 'cheque') {
                formEnviar.banco_tipo = '';
                formEnviar.titular_cuenta = '';
                formEnviar.cbu_cvu = '';
                formEnviar.alias = '';
                formEnviar.banco_nombre = '';
                formEnviar.nro_cuenta = '';
                formEnviar.cuil_cuit = '';
                formEnviar.cuenta_tipo = '';
                formEnviar.cuenta_tipo_otro = '';
            }
            if (form.pago_tipo.toLowerCase() === 'transferencia') {
                formEnviar.cheque_orden = '';
                formEnviar.cheque_concepto = '';
            }

            if (form.pago_tipo.toLowerCase() === 'contado') {
                formEnviar.banco_tipo = '';
                formEnviar.titular_cuenta = '';
                formEnviar.cbu_cvu = '';
                formEnviar.alias = '';
                formEnviar.banco_nombre = '';
                formEnviar.nro_cuenta = '';
                formEnviar.cuil_cuit = '';
                formEnviar.cuenta_tipo = '';
                formEnviar.cuenta_tipo_otro = '';
                formEnviar.cheque_orden = '';
                formEnviar.cheque_concepto = '';
            }

            let lista = listaFormasDePagosAgregadas;
            lista.push(formEnviar);

            //agrego a la lista de formas de pago
            setListaFormasDePagosAgregadas(prevLista => prevLista = lista.slice());
            setlistaFormaPagosTransferencia((prevLista) => prevLista = lista.filter(fp => fp.pago_tipo.toLowerCase().includes('transferencia')));
            setlistaFormaPagosCheques((prevLista) => prevLista = lista.filter(fp => fp.pago_tipo.toLowerCase().includes('cheque')));
            setListaFormaPagosContado((prevLista) => prevLista = lista.filter(fp => fp.pago_tipo.toLowerCase().includes('contado')));

            eventEmmiterLista(lista);

            setHabilitarTipoPago(false);
            Object.assign(form, initialFormFormaPago);
        }
    }

    const eliminarFormaPago = async (formaPago) => {
        let lista = listaFormasDePagosAgregadas.filter(e => e !== formaPago);
        setListaFormasDePagosAgregadas((prevLista) => prevLista = prevLista.filter(e => e !== formaPago));
        setlistaFormaPagosTransferencia((prevLista) => prevLista = lista.filter(fp => fp.pago_tipo.toLowerCase().includes('transferencia')));
        setlistaFormaPagosCheques((prevLista) => prevLista = lista.filter(fp => fp.pago_tipo.toLowerCase().includes('cheque')));
        setListaFormaPagosContado((prevLista) => prevLista = lista.filter(fp => fp.pago_tipo.toLowerCase().includes('contado')));
        eventEmmiterLista(lista.slice());
    }

    const elegirFormaDePago = () => {
        Object.assign(form, initialFormFormaPago);
    }

    const elegirTipoBanco = (tipobanco) => {
        if (tipobanco === 'TRADICIONAL') {
            setBancos(prevLista => prevLista = props.listaBancosTradicionales.slice());
        } else {
            setBancos(prevLista => prevLista = props.listaBancosDigitales.slice());
        }
    }

    return (
        <Fragment>
            <div className='container my-3'>
                <div className="row m-0 d-flex justify-content-start text-start">
                    <div className="col-sm-12 col-md-12 col-lg-12 my-3 border-bottom">
                        <h2>Formas de pago</h2>
                        <p className="text-muted">En esta sección podrás crear la forma de pago.</p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 text-start">
                        <div className="col-sm-12 col-md-12 col-lg-12 px-0">
                            {!habilitarTipoPago && <button className='btn fondo text-white btn-lg' onClick={() => setHabilitarTipoPago(true)}>Crear una forma de Pago</button>}
                        </div>
                        {habilitarTipoPago &&
                            <form >
                                <div className='row m-0 p d-flex justify-content-start'>
                                    <div className="col-sm-12 col-md-6 col-lg-4 my-2 px-0">
                                        <label htmlFor='pago_tipo' className='my-2'> Tipo de pago </label> <br />
                                        <div className="btn-group " role="group" aria-label="Basic radio toggle button group">
                                            <input type="radio" onClick={() => elegirFormaDePago()} className="btn-check my-2" checked={form.pago_tipo === 'TRANSFERENCIA'} name="pago_tipo" value={'TRANSFERENCIA'} onChange={handleChange} id="pago_tipo_1" />
                                            <label className="btn btn-outline-primary" htmlFor="pago_tipo_1">TRANSFERENCIA</label>

                                            <input type="radio" onClick={() => elegirFormaDePago()} className="btn-check my-2" checked={form.pago_tipo === 'CHEQUE'} name="pago_tipo" value={'CHEQUE'} onChange={handleChange} id="pago_tipo_2" />
                                            <label className="btn btn-outline-primary" htmlFor="pago_tipo_2">CHEQUE</label>
                                            <input type="radio" onClick={() => elegirFormaDePago()} className="btn-check my-2" checked={form.pago_tipo === 'CONTADO'} name="pago_tipo" value={'CONTADO'} onChange={handleChange} id="pago_tipo_3" />
                                            <label className="btn btn-outline-primary" htmlFor="pago_tipo_3">CONTADO</label>
                                        </div>
                                    </div>
                                    {form.pago_tipo === 'TRANSFERENCIA' &&
                                        <div className="col-sm-12 col-md-12 col-lg-12 my-2 px-0">

                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <label htmlFor='banco_tipo' className='my-2'> Tipo de Banco </label> <br />
                                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                    <input type="radio" onClick={() => elegirTipoBanco('TRADICIONAL')} className="btn-check" checked={form.banco_tipo === 'TRADICIONAL'} name="banco_tipo" value={'TRADICIONAL'} onChange={handleChange} id="banco_tipo_1" />
                                                    <label className="btn btn-outline-primary" htmlFor="banco_tipo_1">TRADICIONAL</label>

                                                    <input type="radio" onClick={() => elegirTipoBanco('DIGITAL')} className="btn-check" checked={form.banco_tipo === 'DIGITAL'} name="banco_tipo" value={'DIGITAL'} onChange={handleChange} id="banco_tipo_2" />
                                                    <label className="btn btn-outline-primary" htmlFor="banco_tipo_2">DIGITAL</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {form.banco_tipo !== '' && form.pago_tipo !== 'CHEQUE' &&
                                        <div className="col-sm-12 col-md-12 col-lg-12 my-2 px-0">
                                            <div className='row d-flex justify-content-start'>
                                            <div className="col-sm-12 col-md-12 col-lg-12 my-3 border-bottom">
                                                <h3>Datos de Cuenta Bancaria</h3>
                                                <p className="text-muted">En esta sección podrás completar los datos para el pago con Cuenta Bancaria.</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <label htmlFor='titular_cuenta'>Titular de la Cuenta:</label>
                                                <input
                                                    className='form-control col-lg-2 my-1'
                                                    id='titular_cuenta'
                                                    name='titular_cuenta'
                                                    placeholder=''
                                                    type='text'
                                                    value={form.titular_cuenta}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errorsForm.titular_cuenta &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.titular_cuenta}</strong>
                                                    </div>
                                                }

                                            </div>

                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <label htmlFor='cbu_cvu'> {form.banco_tipo === 'TRADICIONAL' ? 'CBU' : 'CBU/CVU'}:</label>
                                                <input
                                                    className='form-control col-lg-2 my-1'
                                                    id='cbu_cvu'
                                                    name='cbu_cvu'
                                                    placeholder=''
                                                    type='text'
                                                    value={form.cbu_cvu}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errorsForm.cbu_cvu &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.cbu_cvu}</strong>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <label htmlFor='alias'>Alias:</label>
                                                <input
                                                    className='form-control col-lg-2 my-1'
                                                    id='alias'
                                                    name='alias'
                                                    placeholder=''
                                                    type='text'
                                                    value={form.alias}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errorsForm.alias &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.alias}</strong>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <label htmlFor='banco_nombre'>Nombre de la Entidad:</label>
                                                <select
                                                    id="banco_nombre"
                                                    className="form-select my-1"
                                                    name="banco_nombre"
                                                    value={form.banco_nombre}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    <option value='Seleccionar' >Seleccionar...</option>
                                                    {bancos.map((b, index) => <option key={index} value={b} >{b}</option>)}
                                                </select>
                                                {errorsForm.banco_nombre &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.banco_nombre}</strong>
                                                    </div>
                                                }

                                            </div>


                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <label htmlFor='nro_cuenta'>Nro de Cuenta:</label>
                                                <input
                                                    className='form-control col-lg-2 my-1'
                                                    id='nro_cuenta'
                                                    name='nro_cuenta'
                                                    placeholder=''
                                                    type='text'
                                                    value={form.nro_cuenta}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errorsForm.nro_cuenta &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.nro_cuenta}</strong>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                <label htmlFor='cuil_cuit'>CUIL/CUIT:</label>
                                                <input
                                                    className='form-control col-lg-2 my-1'
                                                    id='cuil_cuit'
                                                    name='cuil_cuit'
                                                    placeholder=''
                                                    type='text'
                                                    value={form.cuil_cuit}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errorsForm.cuil_cuit &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.cuil_cuit}</strong>
                                                    </div>
                                                }
                                            </div>

                                            {validarTipoBancoTradicional() && <div className="col-sm-12 col-md-6 col-lg-6">

                                                <label htmlFor='cuenta_tipo'>Tipo de Cuenta:</label>
                                                <select
                                                    id="cuenta_tipo"
                                                    className="form-select my-1"
                                                    name="cuenta_tipo"
                                                    value={form.cuenta_tipo}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    <option value='Seleccionar' >Seleccionar...</option>
                                                    {tiposDecuentas.map((tc, index) => <option key={index} value={tc} >{tc}</option>)}
                                                </select>
                                                {errorsForm.cuenta_tipo &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.cuenta_tipo}</strong>
                                                    </div>
                                                }

                                            </div>
                                            }

                                            {validarTipoBancoTradicional() && form.cuenta_tipo === 'OTRO' &&
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <label htmlFor='cuenta_tipo_otro'>Nombre del otro tipo de Cuenta:</label>
                                                    <input
                                                        className='form-control col-lg-2 my-1'
                                                        id='cuenta_tipo_otro'
                                                        name='cuenta_tipo_otro'
                                                        placeholder=''
                                                        type='text'
                                                        value={form.cuenta_tipo_otro}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errorsForm.cuenta_tipo_otro &&
                                                        <div className="invalid-feedback d-block" role="alert">
                                                            <strong>{errorsForm.cuenta_tipo_otro}</strong>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                                                <button type='button' className='btn fondo btn-lg m-1' onClick={() => agregarFormaPago()}> Agregar forma de pago </button>
                                            </div>
                                            </div>
                                        </div>
                                    }
                                    {form.pago_tipo === 'CHEQUE' &&
                                        <div className='row m-0 d-flex justify-content-start my-2 px-0'>
                                             <div className="col-sm-12 col-md-12 col-lg-12 my-3 border-bottom px-0">
                                                <h3>Datos de cheque</h3>
                                                <p className="text-muted">En esta sección podrás completar los datos para el pago con cheque.</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-1 ps-0">
                                                <label htmlFor='cheque_orden'>A la orden de:</label>
                                                <input
                                                    className='form-control col-lg-2 my-1'
                                                    id='cheque_orden'
                                                    name='cheque_orden'
                                                    placeholder=''
                                                    type='text'
                                                    value={form.cheque_orden}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errorsForm.cheque_orden &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.cheque_orden}</strong>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 my-1">
                                                <label htmlFor='cheque_concepto'>Concepto:</label>
                                                <input
                                                    className='form-control col-lg-2 my-1'
                                                    id='cheque_concepto'
                                                    name='cheque_concepto'
                                                    placeholder=''
                                                    type='text'
                                                    value={form.cheque_concepto}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errorsForm.cheque_concepto &&
                                                    <div className="invalid-feedback d-block" role="alert">
                                                        <strong>{errorsForm.cheque_concepto}</strong>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12 my-3 ps-0">
                                                <button type='button' className='btn fondo btn-lg m-1' onClick={() => agregarFormaPago()}> Agregar forma de pago </button>
                                            </div>
                                        </div>
                                    }

                                    {form.pago_tipo === 'CONTADO' &&
                                        <div className='row m-0 d-flex justify-content-start px-0'>
                                            <div className="col-sm-12 col-md-12 col-lg-12 my-3 px-0">
                                                <button type='button' className='btn fondo btn-lg m-1' disabled={listaFormaPagosContado.length > 0} onClick={() => agregarFormaPago()}> Agregar forma de pago </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>

            {listaFormasDePagosAgregadas.length > 0 ?
                <div className="my-4">
                    <div className="container">
                        <div className="row m-0 d-flex justify-content-start">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-start my-3 border-bottom">
                                <h3>Medios de pago agregados <span className="badge bg-secondary text-white">{listaFormasDePagosAgregadas.length}</span> </h3>
                                <p className="text-muted">Estos son los medios de pagos agregados hasta el momento.</p>
                            </div>
                            
                            {listaFormaPagosContado.length > 0 && <h4 className='text-start'>Contado</h4>}
                            {listaFormaPagosContado.map((mp) =>
                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3" key={mp.id}>
                                    <div className="card rounded shadow">
                                        <div className="card-body text-start">
                                            <div className="row m-0 d-flex justify-content-start">
                                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                                    <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-white border-0">
                                                <button className='btn btn-danger rounded-pill text-white my-2' onClick={() => eliminarFormaPago(mp)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {listaFormaPagosTransferencia.length > 0 && <h4 className='text-start mt-3'>Transferencias</h4>}
                            {listaFormaPagosTransferencia.map((mp) =>
                                <div className="col-sm-12 col-md-12 col-lg-12 mb-3" key={mp.id}>
                                    <div className="card rounded shadow">
                                        <div className="card-body text-start">
                                            <div className="row m-0 d-flex justify-content-start">
                                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                                    <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                                    <p className="card-title"><strong>Titular: </strong>{mp.titular_cuenta}</p>
                                                    <p className="card-title"><strong>Tipo de Banco:</strong> {mp.banco_tipo}</p>
                                                    <p className="card-title "><strong>Nombre de la Entidad:</strong> {mp.banco_nombre}</p>
                                                    <p className="card-title"> <strong>{mp.banco_tipo === 'TRADICIONAL' ? 'CBU' : 'CBU/CVU'}:</strong> {mp.cbu_cvu}</p>
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-6'>


                                                    <p className="card-title"><strong>Alias: </strong>{mp.alias}</p>
                                                    <p className="card-title"> <strong>Nro Cuenta:</strong> {mp.nro_cuenta}</p>
                                                    {mp.banco_tipo === 'TRADICIONAL' && <p className="card-title"><strong>Tipo de Cuenta:</strong> {mp.cuenta_tipo}</p>}
                                                    {mp.banco_tipo === 'TRADICIONAL' && mp.cuenta_tipo.includes('OTRO') && <p className="card-title"><strong>Otro Tipo de Cuenta:</strong> {mp.cuenta_tipo_otro}</p>}
                                                    <p className="card-title"><strong>CUIL/CUIT: </strong>{mp.cuil_cuit}</p>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-white border-0">
                                                <button className='btn btn-danger rounded-pill text-white my-2' onClick={() => eliminarFormaPago(mp)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {listaFormaPagosCheques.length > 0 && <h4 className='text-start mt-3'>Cheques</h4>}

                            {listaFormaPagosCheques.map((mp) =>
                                <div className="col-sm-12 col-md-6 col-lg-6 mb-3" key={mp.id}>
                                    <div className="card rounded shadow">
                                        <div className="card-body text-start">
                                            <p className="card-title"><strong>Tipo de pago: </strong> {mp.pago_tipo}</p>
                                            <p className="card-title"><strong>A la orden de: </strong>{mp.cheque_orden}</p>
                                            <p className="card-title"><strong>Concepto: </strong> {mp.cheque_concepto}</p>
                                            <button className='btn btn-danger rounded-pill text-white my-2' onClick={() => eliminarFormaPago(mp)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                :
                <div className="container my-4">
                    <div className='alert m-2 alert-warning container'>
                        <p className='mb-0'>No tiene ningún medio de pago agregado. Por favor crear un nuevo medio de pago</p>
                    </div>
                </div>   
            }
        </Fragment>
    );
}

export default FormFormaPagoComponent;

