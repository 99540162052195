import React, { Fragment } from 'react';
import HeaderComponent from '../../shared/Header';
import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import imagenChecked from '../../assets/images/checked.png';
import ModalComponent from '../../components/ModalComponent';
const endpoint = process.env.REACT_APP_BACKEND_URL;


export const FormConfirmarMailComponent = () => {

    const [habilitarMensaje, setHabilitarMensaje] = useState(false);
    const [errorsForm, setErrorsForm] = useState({});
    const [habiltarMensaje, sethabiltarMensaje] = useState(false);
    const [deshabiltarContinuar, setDehabiltarContinuar] = useState(false);
    const [mensajeButton, setMensajeButton] = useState('Continuar');
    const [dataModal, setDataModal] = useState(null);

    const continuar = async (e) => {
        sethabiltarMensaje(false);
        setDataModal(null);
        e.preventDefault();
        let email = e.target.email.value;
        setErrorsForm(validations(email));
        if (Object.keys(validations(email)).length === 0) {
            //no hay errores
            await axios.get(`${endpoint}/proveedores/email/${email}`).then(response => {
                setMensajeButton('Enviando código de confirmación');
                enviar(email);
                setDehabiltarContinuar(true);
            }).catch((error) => {
                console.error('error al enviar código de confirmacion al proveedor:', error);
                setDataModal({
                    titulo: 'Hubo un error',
                    descripcion: error.response.data.details,
                    icono: 'danger'
                });
            });
        }
    }

    const enviar = async (email) => {
        await axios.get(`${endpoint}/update/register/${email}`).then(response => {
            setHabilitarMensaje(true)
            setMensajeButton('Continuar');
        }).catch((error) => {
            console.error('error al enviar Código de confirmacion al proveedor:', error);
            setDataModal({
                titulo: 'Hubo un error',
                descripcion: error.response.data.details,
                icono: 'danger'
            });
        });
    }

    const validations = (email) => {
        let errors = {};
        let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/;

        if (!email.trim()) {
            errors.email = "El campo Correo electrónico es requerido";
        } else if (!regexEmail.test(email.trim())) {
            errors.email = "El campo Correo electrónico es incorrecto";
        }

        return errors;
    };

    return (
        <Fragment>
            <HeaderComponent />
            {!habilitarMensaje ?
                <div className="my-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="">

                                    <div className="card-body">
                                        <div className="row m-0 d-flex justify-content-center text-center">
                                            <div className="col-sm-12 col-md-12 col-lg-12 m-2 d-flex justify-content-center">
                                                <div className="card col-sm-12 col-md-12 col-lg-6 shadow rounded">
                                                    <div className="card-header" style={{ backgroundColor: '#eff2f6' }}>
                                                        <h5 className="card-title py-2 my-2">Modificar mis datos de Proveedor</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="card-text">
                                                            Ingrese la dirección de correo electrónico que usó para su registro como proveedor.  Usted recibirá en su
                                                            casilla de correo un enlace  para continuar con la modificación de sus datos.
                                                        </p>
                                                        <form onSubmit={(e) => continuar(e)} >
                                                            <div className="row m-0 d-flex justify-content-start">
                                                                <div className="col-12 my-2 ">
                                                                    <input className="form-control col-12 me-2" type="search" placeholder="Correo electrónico" name="email" aria-label="Search" />
                                                                </div>
                                                                {errorsForm.email &&
                                                                    <div className="invalid-feedback d-block text-start" role="alert">
                                                                        <strong>{errorsForm.email}</strong>
                                                                    </div>
                                                                }

                                                                {dataModal &&
                                                                    <div className="col-12 text-lg-end pe-0">
                                                                        < ModalComponent
                                                                            dataModal={dataModal}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <input type="submit" className="btn fondo btn-lg mx-1 my-3" disabled={deshabiltarContinuar} value={mensajeButton} />
                                                            <Link className='btn btn-lg mx-1 btn-light my-3' to="/">Cancelar</Link>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="my-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="">

                                    <div className="card-body">
                                        <div className="row m-0 d-flex justify-content-center text-center">
                                            <div className="col-sm-12 col-md-12 col-lg-12 m-2 d-flex justify-content-center">
                                                <div className="card col-sm-12 col-md-12 col-lg-6 shadow rounded">
                                                    <div className="card-body my-3">
                                                        <img src={imagenChecked} className="img-fluid m-2 " width={70} alt="logo" />
                                                        <h5 className="card-title"> <strong>¡Listo!</strong> Revisa tu correo</h5>
                                                        <p className="card-text">
                                                            Te hemos enviado un correo para confirmar tu cuenta, por favor haga clic en enlace para modificar sus datos.
                                                        </p>
                                                        <p className="card-text">
                                                            Si no ha recibido el mensaje en su bandeja de entrada por favor revise el correo spam.
                                                        </p>

                                                        <Link className='btn fondo btn-lg m-1' to="/">Ir al Inicio</Link>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}
export default FormConfirmarMailComponent;
