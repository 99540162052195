import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'

import { Button } from "reactstrap";
import ModalComponent from '../../components/ModalComponent';

import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from "../../hook/useForm";
import HeaderComponent from '../../shared/Header';
import logoPais from '../../assets/images/argentina.png';
import axios from 'axios';
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import DocumentacionProveedor from './DocumentacionProveedor';
import FormFormaPagoComponent from './FormFormaPago';
import VistaPreviaProveedor from './VistaPreviaProveedor';
import imagenChecked from '../../assets/images/checked.png';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Loader from '../../components/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Proveedor', 'Documentación', 'Forma de Pago', 'Confirmar y Enviar'];
}

const initialFormProveedor = {
    id: null,
    pais_id: null,
    cuit: '',
    razon_social: '',
    rubro_actividad_id: '',
    domicilio_comercial: '',
    nombre_contacto: '',
    cod_area: '',
    nro_telefono: '',
    email: '',
    condicion_iva: '',
    contribuyente_cm: 0,
    agente_percepcion_iva: 0,
    agente_percepcion_iibb: 0,
    agente_percepcion_iibb_descripcion: '',
    ingresos_brutos: ''
};

const endpoint = process.env.REACT_APP_BACKEND_URL;

const FormProveedorComponent = () => {

    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [listaFormasDePago, setListaFormasDePago] = useState([]);

    const { id } = useParams();
    const [idPais] = useState(1); // argentina
    const [paisElegido, setPaisElegido] = useState();
    const [textButtonConfirmar, setTextButtonConfirmar] = useState('Confirmar y Enviar');
    const [deshabilitarBotonConfirmar, setDeshabilitarBotonConfirmar] = useState(false);

    const [dataModal, setDataModal] = useState(null);

    useEffect(() => {

        getListasSelect();

        if (+id !== 0) {
            getProveedor(id);
        } else {
            getPais(idPais);
        }

    }, [])

    const navigate = useNavigate();
    const getListasSelect = async () => {
        try {
            // Función auxiliar para manejar la llamada a la API
            const fetchConfig = async (clave) => {
                const response = await axios.get(`${endpoint}/sysconfigs/clave/${clave}`);
                return JSON.parse(response.data.data.valor);
            };

            // Obtener condiciones IVA
            const condicionesIva = await fetchConfig('condiciones-iva');
            setListaCondicionesIva(condicionesIva.values.slice());

            // Obtener tipos de cuentas
            const tiposCuentas = await fetchConfig('tipos-cuentas');
            setListaTipoDeCuentas(tiposCuentas.values.slice());

            // Solo si id es 0, obtener categorías de documentación
            if (+id === 0) {
                const categoriasDocumentacion = await fetchConfig('categorias-documentacion');
                setListaCategoriasDocumentacion(categoriasDocumentacion.values.slice());

                // Crear la lista de documentos
                const listadocumentosGenerada = categoriasDocumentacion.values.map((nombreCategoria, index) => ({
                    id: index + 1,
                    proveedor_id: null,
                    nombre_archivo: '',
                    archivo: null,
                    ruta_archivo: '',
                    categoria: nombreCategoria,
                    estado: false
                }));
                setListaDocumentos(listadocumentosGenerada);
            }

            // Obtener rubros de actividades
            const rubrosActividadesResponse = await axios.get(`${endpoint}/actividades`);
            setListaRubrosActividades(rubrosActividadesResponse.data?.data);

            // Obtener bancos tradicionales
            const bancosTradicionales = await fetchConfig('bancos-tradicionales');
            setListaBancosTradicionales(bancosTradicionales.values.sort().slice());

            // Obtener bancos digitales
            const bancosDigitales = await fetchConfig('bancos-digitales');
            setListaBancosDigitales(bancosDigitales.values.slice());

        } catch (error) {
            console.error('Error al obtener las listas:', error);
            setDataModal({
                titulo: 'Hubo un error',
                descripcion: 'No fue posible cargar datos de configuración. ',
                icono: 'danger'
            });
        }
    };

    const getPais = async (id) => {
        const response = await axios.get(`${endpoint}/paises/${id}`);
        let pais = response.data?.data;
        if (pais) {
            setPaisElegido(pais);
            form.pais_id = pais.id;
        }
    }

    const getProveedor = async (id) => {
        setDataModal(null);
        try {
            const response = await axios.get(`${endpoint}/proveedores/${id}`);
            let proveedor = response.data.data;

            if (proveedor) {
                if (id !== 0 && !proveedor.confirmado && proveedor.codigo_confirmacion) {
                    // No confirmó su correo
                    navigate("/");
                } else {
                    for (const key in proveedor) {
                        if (proveedor[key] === null) {
                            proveedor[key] = '';
                        }
                    }
                    setPaisElegido(proveedor.pais);
                    delete proveedor.pais;
                    delete proveedor.forma_pagos;
                    delete proveedor.rubro_actividad;
                    delete proveedor.created_at;
                    delete proveedor.updated_at;
                    Object.assign(form, proveedor);
                    getDocumentacionDelProveedor(id);
                }
            }
        } catch (error) {
            console.error("Error al obtener el proveedor:", error);
            setDataModal({
                titulo: 'Hubo un error',
                descripcion: error.response.data.details,
                icono: 'danger'
            });
        }
    }

    const [listaDocumentosProveedorOriginal, setListaDocumentosProveedorOriginal] = useState([]);
    const [listaFormasDePagoProveedorOriginal, setListaFormasDePagoProveedorOriginal] = useState([]);

    const getDocumentacionDelProveedor = async (idProveedor) => {
        setDataModal(null);
        try {

            const response = await axios.get(`${endpoint}/documentacion/proveedor/${idProveedor}`);
            setListaDocumentosProveedorOriginal(response.data.data);
            let listaDocActualesCargados = response.data.data;

            //obtengo del system 
            let responseCategorias = await axios.get(`${endpoint}/sysconfigs/clave/categorias-documentacion`);
            let config = JSON.parse(responseCategorias.data.data.valor);
            setListaCategoriasDocumentacion(prevLista => prevLista = config.values.slice());

            //crear la lista de documentos generada
            let listadocumentosGenerada = [];
            config.values.forEach((nombreCategoria, index) => {
                listadocumentosGenerada.push(
                    { id: index + 1, proveedor_id: null, nombre_archivo: '', archivo: null, ruta_archivo: '', categoria: nombreCategoria, estado: false }
                );
            });
            setListaDocumentos(prevLista => prevLista = listadocumentosGenerada.slice())
            let listaDocs = listadocumentosGenerada;

            //asigno los documentos que ya tiene el proveedor a la lista de documentos generada
            listaDocActualesCargados.forEach(doc => {
                let pos = listaDocs.findIndex(d => d.categoria.toUpperCase() === doc.categoria.toUpperCase());
                if (pos !== -1) {
                    Object.assign(listaDocs[pos], doc);
                    listaDocs[pos].estado = true;
                    listaDocs[pos].archivo = doc;
                }
            });
            setListaDocumentos((prevLista) => prevLista = listaDocs);
            getFormasPagosDelProveedor(id);
        } catch (error) {
            console.error("Error al obtener documentacion del proveedor:", error);
            setDataModal({
                titulo: 'Hubo un error',
                descripcion: error.response.data.details,
                icono: 'danger'
            });
        }
    }

    const getFormasPagosDelProveedor = async (idProveedor) => {
        setDataModal(null);
        try {
            const response = await axios.get(`${endpoint}/formapagos/proveedor/${idProveedor}`);
            setListaFormasDePago(response.data?.data);
            setListaFormasDePagoProveedorOriginal(response.data?.data.slice());
        } catch (error) {
            console.error('Error al obtener las formas de pago del proveedor:', error);
            setDataModal({
                titulo: 'Hubo un error',
                descripcion: error.response.data.details,
                icono: 'danger'
            });
        }
    }

    const [listaCondicionesIva, setListaCondicionesIva] = useState([])
    const [listaRubrosActividades, setListaRubrosActividades] = useState([]);
    const [listaBancosTradicionales, setListaBancosTradicionales] = useState([]);
    const [listaBancosDigitales, setListaBancosDigitales] = useState([]);
    const [listaTipoDeCuentas, setListaTipoDeCuentas] = useState([]);
    const [listaCategoriasDocumentacion, setListaCategoriasDocumentacion] = useState([]);

    //PASO A PASO
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const [listaErroresDocs, setListaErroresDocs] = useState([]);
    const handleNext = (activeStep) => {
        switch (activeStep) {
            case 0:
                setErrorsForm(validationsForm(form));
                if (Object.keys(validationsForm(form)).length === 0) {
                    let listaDocSinArchivos = [];
                    setListaErroresDocs(prevList => prevList = listaDocSinArchivos.splice());
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                break;

            case 1:
                let listaDocSinArchivos = [];
                setListaErroresDocs(prevList => prevList = listaDocSinArchivos.splice());

                //archivos que son obligatorios
                let listaCategoriaObligatorias = [];

                if (+form.contribuyente_cm === 1) {
                    listaCategoriaObligatorias.push('CONVENIO_CM');
                }

                listaCategoriaObligatorias.push('IIBB');
                listaCategoriaObligatorias.push('AFIP');


                let contDocObligatorios = 0;
                listaDocumentos.forEach(doc => {
                    if (listaCategoriaObligatorias.includes(doc.categoria)) {
                        if (!doc.archivo) {
                            listaDocSinArchivos.push(doc);
                        } else {
                            contDocObligatorios++;
                        }
                    }
                });
                setListaErroresDocs(prevList => prevList = listaDocSinArchivos.slice());

                if (listaCategoriaObligatorias.length === contDocObligatorios) {
                    //esta OK
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

                break;
            case 2:
                if (listaFormasDePago.length > 0) {
                    //esta OK
                    if (+id !== 0) {
                        setTextButtonConfirmar('Guardar cambios');

                    }
                    setDataModal(null);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    //TOKEN REACHAPCHAT V3
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    const [actionToChange, setActionToChange] = useState('');

    const registerUser = useCallback(async () => {

        if (!executeRecaptcha) {
            return;
        }
        const result = await executeRecaptcha('register');
        setToken(result);

    }, [executeRecaptcha]);

    const handleReCaptchaVerify = async () => {
        const token = await executeRecaptcha('register');
        setToken(token);
    };

    const handleTextChange = useCallback(event => {
        setActionToChange(event.target.value);
    }, []);

    //FORMULARIO PROVEEDOR

    const {
        form,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialFormProveedor);

    const [errorsForm, setErrorsForm] = useState({});
    const validationsForm = (form) => {
        let errors = {};
        let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/;
        let regCantidadCaracteresLargo = /^.{1,100}$/;
        let regCantidadCaracteresMedio = /^.{1,10}$/;
        let regCantidadCaracteresCorto = /^.{1,5}$/;
        let regCuitLongitud = /^.{10,11}$/;
        let regNumeros = /^([0-9])*$/;

        if (!form.cuit.trim()) {
            errors.cuit = "El campo CUIT es requerido";
        } else if (!regNumeros.test(form.cuit.trim())) {
            errors.cuit = 'El campo CUIT solo permite numeros';
        }
        else if (!regCuitLongitud.test(form.cuit.trim())) {
            errors.cuit = 'El campo CUIT tiene que tener 11 digitos';
        }
        if (!form.razon_social.trim()) {
            errors.razon_social = "El campo Razón Social es requerido";
        } else if (!regCantidadCaracteresLargo.test(form.razon_social.trim())) {
            errors.razon_social = "El campo Razón Social no debe superar 100 caracteres";
        }
        else if (form.razon_social.trim().length < 5) {
            errors.razon_social = 'El campo Razón Social debe tener mas caracteres.';
        }
        if (!form.domicilio_comercial.trim()) {
            errors.domicilio_comercial = "El campo Domicilio comercial es requerido";
        } else if (!regCantidadCaracteresLargo.test(form.domicilio_comercial.trim())) {
            errors.domicilio_comercial = "El campo Domicilio comercial no debe superar 100 caracteres";
        }
        else if (form.domicilio_comercial.trim().length < 5) {
            errors.domicilio_comercial = 'El campo Domicilio comercial debe tener mas caracteres.';
        }
        if (!form.nombre_contacto.trim()) {
            errors.nombre_contacto = "El campo Nombre de contacto es requerido";
        } else if (!regCantidadCaracteresLargo.test(form.nombre_contacto.trim())) {
            errors.nombre_contacto = "El campo Nombre de contacto no debe superar 100 caracteres";
        }
        else if (form.nombre_contacto.trim().length < 5) {
            errors.nombre_contacto = 'El campo Nombre de contacto debe tener mas caracteres.';
        }
        if (!form.cod_area.trim()) {
            errors.cod_area = "El campo Cod. de Área es requerido";
        } else if (!regNumeros.test(form.cod_area.trim())) {
            errors.cod_area = 'El campo Cod. de Área solo permite numeros';
        }
        else if (!regCantidadCaracteresCorto.test(form.cod_area.trim())) {
            errors.cod_area = "El campo Cod. de Área no debe superar 5 caracteres";
        }

        if (!form.nro_telefono.trim()) {
            errors.nro_telefono = "El campo Nro de teléfono es requerido";

        }
        else if (!regNumeros.test(form.nro_telefono.trim())) {
            errors.nro_telefono = 'El campo Nro de teléfono solo permite numeros';
        }
        else if (!regCantidadCaracteresMedio.test(form.nro_telefono.trim())) {
            errors.nro_telefono = "El campo Nro de teléfono no debe superar 10 caracteres";
        }

        if (!form.rubro_actividad_id.toString().trim() || form.rubro_actividad_id.toString().trim().toLowerCase().includes('seleccionar')) {
            errors.rubro_actividad_id = "El campo Rubro/Actividad es requerido";
        }

        if (!form.condicion_iva.trim() || form.condicion_iva.trim().toLowerCase().includes('seleccionar')) {
            errors.condicion_iva = "El campo condición iva es requerido";
        }
        if (!form.email.trim()) {
            errors.email = "El campo Correo electrónico es requerido";
        } else if (!regexEmail.test(form.email.trim())) {
            errors.email = "El campo Correo electrónico es incorrecto";
        }

        if (!form.ingresos_brutos.trim()) {
            errors.ingresos_brutos = "El campo Nro Inscripción de Ingresos Brutos es requerido";

        } else if (!regNumeros.test(form.ingresos_brutos.trim())) {
            errors.ingresos_brutos = 'El campo Nro Inscripción de Ingresos Brutos solo permite numeros';
        }

        if (form.agente_percepcion_iibb === '1') {
            if (!form.agente_percepcion_iibb_descripcion.trim()) {
                errors.agente_percepcion_iibb_descripcion = "El campo Descripción de Jurisdicciones es requerido";
            } else if (!regCantidadCaracteresLargo.test(form.agente_percepcion_iibb_descripcion.trim())) {
                errors.agente_percepcion_iibb_descripcion = "El campo Ingresos Brutos no debe superar 100 caracteres";
            }
        }

        return errors;
    };

    const [contDocumentosSubidos, setContDocumentosSubidos] = useState(0);
    const [cantDocumentosASubir, setCantDocumentosASubir] = useState(0);
    const [contFormasPago, setContFormasPago] = useState(0);
    const [cantFormasPagoASubir, setCantFormasPagoASubir] = useState(0);

    const enviarYConfirmar = async () => {

        setContDocumentosSubidos(0);
        setContFormasPago(0);
        setCantDocumentosASubir(0);

        setDeshabilitarBotonConfirmar(true);
        setDataModal(null);

        let formularioProveedor = { codigo_confirmacion: null, confirmado: false, token: token };
        //obtengo el token recapcha
        await executeRecaptcha('register').then(responseToken => {

            setToken(prevToken => prevToken = responseToken);
            formularioProveedor.token = responseToken;

            formularioProveedor = Object.assign(formularioProveedor, form);
            formularioProveedor.agente_percepcion_iva = +formularioProveedor.agente_percepcion_iva;
            formularioProveedor.agente_percepcion_iibb = +formularioProveedor.agente_percepcion_iibb;
            formularioProveedor.rubro_actividad_id = +formularioProveedor.rubro_actividad_id;
            formularioProveedor.pais_id = paisElegido.id;

            if (+id !== 0) {
                //ACTUALIZANDO
                setTextButtonConfirmar('Guardando...');
                axios.put(`${endpoint}/proveedores/${formularioProveedor.id}`, formularioProveedor).then(response => {
                    if (response.data.success) {
                        //verificamos los medios de pagos
                        let listaNoEliminarFP = [];
                        let listaSiAgregarFP = [];
                        let listaAEliminarFP = [];

                        listaFormasDePagoProveedorOriginal.forEach(d => {
                            let encontrado = listaFormasDePago.find(doc => doc.hasOwnProperty('id') && doc.id === d.id);
                            if (encontrado) {
                                listaNoEliminarFP.push(d);
                            } else {
                                listaAEliminarFP.push(d);
                            }
                        });
                        listaSiAgregarFP = listaFormasDePago.filter(doc => !doc.hasOwnProperty('id'));

                        //elimino los que fp que ya no tiene que estar en  bd
                        if (listaAEliminarFP.length > 0) {
                            listaAEliminarFP.forEach(async (fp) => {
                                await axios.delete(`${endpoint}/formapagos/${fp.id}`).then(responsefp => {
                                    if (responsefp.data.success) {
                                        setContFormasPago(prevCont => prevCont = prevCont + 1);
                                    }
                                }).catch(error => {
                                    console.error('Error al eliminar forma de pago', error)
                                    setDataModal({
                                        titulo: 'Hubo un error',
                                        descripcion: error.response.data.details,
                                        icono: 'danger'
                                    });
                                });

                            });
                        }

                        //forma de pago hay para agregar

                        if (listaSiAgregarFP.length > 0) {
                            listaSiAgregarFP.forEach(async (fp) => {
                                fp.proveedor_id = formularioProveedor.id;
                                await axios.post(`${endpoint}/formapagos`, fp).then(responsefp => {
                                    if (responsefp.data.success) {
                                        setContFormasPago(prevCont => prevCont = prevCont + 1);
                                    }
                                }).catch(error => {
                                    console.error('Error al crear forma de pago', error)
                                    setDataModal({
                                        titulo: 'Hubo un error',
                                        descripcion: error.response.data.details,
                                        icono: 'danger'
                                    });
                                });

                            });
                        }
                        setCantFormasPagoASubir(cantprev => cantprev = listaAEliminarFP.length + listaSiAgregarFP.length);

                        //verfico los documentos
                        let listaNoEliminar = [];
                        let listaEliminar = [];
                        let listaEliminarActualizar = [];
                        let listaDocAgregar = [];

                        listaDocumentosProveedorOriginal.forEach(d => {
                            let encontrado = listaDocumentos.find(doc => doc.nombre_archivo === d.nombre_archivo && doc.ruta_archivo === d.ruta_archivo);
                            if (encontrado) {
                                listaNoEliminar.push(d);
                            }
                            encontrado = listaDocumentos.find(doc => doc.categoria.toLowerCase() === d.categoria.toLowerCase() && doc.estado === false && doc.nombre_archivo === '' && !doc.archivo && doc.ruta_archivo === '');
                            if (encontrado) {
                                listaEliminar.push(d);
                            }
                            encontrado = listaDocumentos.find(doc => doc.categoria.toLowerCase() === d.categoria.toLowerCase() && doc.estado === true && doc.nombre_archivo !== '' && doc.nombre_archivo !== d.nombre_archivo
                                && doc.archivo && doc.ruta_archivo === '');
                            if (encontrado) {
                                listaEliminarActualizar.push(d);
                            }

                        });

                        listaDocAgregar = listaDocumentos.filter(d => d.archivo && d.proveedor_id === null && d.estado === true && d.nombre_archivo !== '' && d.ruta_archivo === '');
                        setCantDocumentosASubir(cantprev => cantprev = listaEliminar.length + listaEliminarActualizar.length + listaDocAgregar.length);

                        //documentacion a eliminar
                        if (listaEliminar.length > 0) {
                            listaEliminar.forEach(async (doc) => {
                                await axios.delete(`${endpoint}/documentacion/${doc.id}`).then(respDoc => {
                                    if (respDoc.data.success) {
                                        setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                    }
                                }).catch(error => {
                                    console.error('Error al eliminar documentación', error)
                                    setDataModal({
                                        titulo: 'Hubo un error',
                                        descripcion: error.response.data.details,
                                        icono: 'danger'
                                    });
                                });

                            });
                        }

                        //documentacion que para actualizar o eliminar
                        if (listaEliminarActualizar.length > 0) {

                            const fd = new FormData();
                            listaEliminarActualizar.forEach(async documento => {
                                //elimino el archivo del documento anterior de la bd
                                await axios.delete(`${endpoint}/documentacion/archivo/${documento.id}`).then(async responseEliminarArchivo => {
                                    
                                    if (responseEliminarArchivo.data.success) {

                                        //si existe el archivo lo agrego
                                        let doc = listaDocumentos.find(e => e.id === documento.id)
                                        doc.proveedor_id = form.id;
                                        fd.append(`file${doc.id}`, doc.archivo, doc.nombre);
                                        fd.append('id', doc.id.toString());

                                        //subimos el nuevo documento nuevo
                                        await axios.post(`${endpoint}/upload`, fd).then(async respUpload => {

                                            if (respUpload.data.success) {
                                                fd.delete(`file${doc.id}`);
                                                fd.delete('id');
                                                doc.ruta_archivo = respUpload.data.path;
                                                doc.nombre_archivo = respUpload.data.path.split('/')[respUpload.data.path.split('/').length - 1];

                                                delete doc.id;
                                                delete doc.archivo;
                                                delete doc.estado;
                                                //actualizamos la documentacion
                                                await axios.post(`${endpoint}/documentacion`, doc).then(respDoc => {
                                                    if (respDoc.data.success) {
                                                        setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                                    }
                                                }).catch(error => {
                                                    console.errir('Error al actualizar documentación', error)
                                                    setDataModal({
                                                        titulo: 'Hubo un error',
                                                        descripcion: error.response.data.details,
                                                        icono: 'danger'
                                                    });
                                                });
                                            }

                                        }).catch(error => {
                                            console.error('error al subir la documentacion upload', error)
                                            setDataModal({
                                                titulo: 'Hubo un error',
                                                descripcion: error.response.data.details,
                                                icono: 'danger'
                                            });
                                        });
                                    }
                                }).catch(error => {
                                    console.error('Error al eliminar archivo en upgrade', error)
                                    setDataModal({
                                        titulo: 'Hubo un error',
                                        descripcion: error.response.data.details,
                                        icono: 'danger'
                                    });
                                });
                            });
                        }

                        if (listaDocAgregar.length > 0) {
                            const fd = new FormData();
                            listaDocAgregar.forEach(async doc => {
                                //subimos el archivo
                                doc.proveedor_id = formularioProveedor.id;
                                fd.append(`file${doc.id}`, doc.archivo, doc.nombre);
                                fd.append('id', doc.id.toString());

                                await axios.post(`${endpoint}/upload`, fd).then(async respUpload => {
                                    if (respUpload.data.success) {

                                        fd.delete(`file${doc.id}`);
                                        fd.delete('id');
                                        doc.ruta_archivo = respUpload.data.path;
                                        doc.nombre_archivo = respUpload.data.path.split('/')[respUpload.data.path.split('/').length - 1];
                                        doc.updated_at = new Date()
                                        delete doc.id;
                                        delete doc.archivo;
                                        delete doc.estado;

                                        //registramos la documentacion
                                        await axios.post(`${endpoint}/documentacion`, doc).then(respDoc => {
                                            if (respDoc.data.success) {
                                                setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                            }
                                        }).catch(error => {
                                            console.error('Error al crear documentación', error)
                                            setDataModal({
                                                titulo: 'Hubo un error',
                                                descripcion: error.response.data.details,
                                                icono: 'danger'
                                            });
                                        });
                                    }

                                }).catch(error => {
                                    console.error('error al subir la documentacion upload', error);
                                    setDataModal({
                                        titulo: 'Hubo un error',
                                        descripcion: error.response.data.details,
                                        icono: 'danger'
                                    });
                                });
                            });
                        }

                        setTextButtonConfirmar('Guardar cambios');
                        setDeshabilitarBotonConfirmar(false);
                        handleNext(activeStep);
                    } else {
                        setTextButtonConfirmar('Guardar cambios');
                        setDeshabilitarBotonConfirmar(false);

                        setDataModal({
                            titulo: 'Hubo un error',
                            descripcion: response.data.details,
                            icono: 'danger'
                        });
                    }

                }).catch(function (error) {
                    setDeshabilitarBotonConfirmar(false);
                    setTextButtonConfirmar('Guardar cambios');

                    if (error.response.data.errors) {
                        const errores = error.response.data.errors;
                        const mensajes = [];

                        for (const campo in errores) {
                            if (errores[campo].length > 0) {
                                mensajes.push(...errores[campo]);
                            }
                        }

                        console.error('errores:', error.response.data);
                        setDataModal({
                            titulo: 'Hubo un error',
                            descripcion: mensajes.length > 0 ? mensajes.join('\n') : "No hay errores que mostrar.",
                            icono: 'danger'
                        });


                    } else {
                        console.error('error:', error.response.data);
                        setDataModal({
                            titulo: 'Hubo un error',
                            descripcion: error.response.data.details,
                            icono: 'danger'
                        });
                    }
                });
            } else {
                //CREANDO
                delete formularioProveedor.id;
                //GUARDO EL PROVEEDOR
                setTextButtonConfirmar('Enviando...');
                
                axios.post(`${endpoint}/proveedores`, formularioProveedor).then(response => {
                    
                    if (response.data.success) {
                        let proveedor = response.data.data;

                        //guardar la documentacion
                        const fd = new FormData();

                        let listaDocs = listaDocumentos.filter(d => d.archivo);
                        setCantDocumentosASubir(prevCont => prevCont = listaDocs.length);

                        listaDocs.forEach(async doc => {
                            doc.proveedor_id = proveedor.id;
                            fd.append(`file${doc.id}`, doc.archivo, doc.nombre);
                            fd.append('id', doc.id.toString());

                            await axios.post(`${endpoint}/upload`, fd).then(async respUpload => {
                                
                                if (respUpload.data.success) {

                                    fd.delete(`file${doc.id}`);
                                    fd.delete('id');
                                    doc.ruta_archivo = respUpload.data.path;
                                    doc.nombre_archivo = respUpload.data.path.split('/')[respUpload.data.path.split('/').length - 1];
                                    
                                    delete doc.id;
                                    delete doc.archivo;
                                    delete doc.estado;

                                    await axios.post(`${endpoint}/documentacion`, doc).then(respDoc => {
                                        if (respDoc.data.success) {
                                            setContDocumentosSubidos(prevCont => prevCont = prevCont + 1);
                                        }
                                    }).catch(error => {
                                        console.error('error documentacion', error)
                                        setDataModal({
                                            titulo: 'Hubo un error',
                                            descripcion: error.response.data.details,
                                            icono: 'danger'
                                        });
                                    });
                                }

                            }).catch(error => {
                                console.error('error al subir la documentacion upload', error)
                                setDataModal({
                                    titulo: 'Hubo un error',
                                    descripcion: error.response.data.details,
                                    icono: 'danger'
                                });
                            });
                        });

                        //guardar las formas de pago
                        setCantFormasPagoASubir(cantprev => cantprev = listaFormasDePago.length);

                        setDataModal(null);
                        listaFormasDePago.forEach(async formaPago => {
                            delete formaPago.id
                            formaPago.proveedor_id = proveedor.id;
                            
                            await axios.post(`${endpoint}/formapagos`, formaPago).then(response => {
                                if (response) {
                                    setContFormasPago(prevCont => prevCont = prevCont + 1)
                                }
                            }).catch(error => {
                                console.error('error forma de pago', error)
                                setDataModal({
                                    titulo: 'Hubo un error',
                                    descripcion: error.response.data.details,
                                    icono: 'danger'
                                });
                            });

                        });

                        handleNext(activeStep);

                    } else {
                        setDeshabilitarBotonConfirmar(false);
                        setTextButtonConfirmar('Confirmar y Enviar');
                        setDataModal({
                            titulo: 'Hubo un error',
                            descripcion: response.data.details,
                            icono: 'danger'
                        });
                    }
                }).catch(function (error) {
                    console.error('error del caht:', error);
                    setDeshabilitarBotonConfirmar(false);
                    setTextButtonConfirmar('Confirmar y Enviar');
                 
                    if (error.response.data.errors) {
                        const errores = error.response.data.errors;
                        const mensajes = [];

                        for (const campo in errores) {
                            if (errores[campo].length > 0) {
                                mensajes.push(...errores[campo]);
                            }
                        }

                        console.error('errores:', error.response.data);
                        setDataModal({
                            titulo: 'Hubo un error',
                            descripcion: mensajes.length > 0 ? mensajes.join('\n') : "No hay errores que mostrar.",
                            icono: 'danger'
                        });
                        
                    } else {
                        console.error('errores:', error.response.data);
                        setDataModal({
                            titulo: 'Hubo un error',
                            descripcion: error.response.data.details,
                            icono: 'danger'
                        });
                    }
                });
            }

        }).catch((errors) => {
            console.error('Hubo errores al obtener el token:', errors);

        })
    }

    //recuperar datos de los otros componentes
    const enviarRecuperarFormasDePago = (listaFormaDePago) => {
        setListaFormasDePago((preLista) => preLista = listaFormaDePago.slice());
    }

    const reinciarAgenteIbb = () => {
        if (+form.agente_percepcion_iibb === 0) {
            let proveedor = form;
            proveedor.agente_percepcion_iibb_descripcion = '';
            Object.assign(form, proveedor);
        }
    }

    const enviarRecuperarDocumentos = (listaDocumentos) => {
        setListaDocumentos((preLista) => preLista = listaDocumentos.slice());
    }

    //validar datos de carga al cargar la pagina
    const faltaCargarDatos = () => {
        return listaRubrosActividades.length === 0 || listaCondicionesIva.length === 0 || listaDocumentos.length === 0 || !paisElegido;
    }

    return (
        <Fragment>
            <HeaderComponent isAdmin={false} />
            <div className={classes.root}>
                <div className='row mx-0 my-4 d-flex justify-content-center'>
                    <div className='col-sm-12 col-md-8 col-lg-7 border shadow-sm rounded-top p-0'>
                        <Stepper activeStep={activeStep} alternativeLabel style={{ backgroundColor: '#eff2f6', paddingLeft: '0px', paddingRight: '0px' }}>
                            {steps.map((label) => (
                                <Step key={label} style={{ color: '#ffffff', padding: '0px', margin: '0px' }}>
                                    <StepLabel StepIconProps={{ style: { width: '50px', height: '50px' } }} >{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    {faltaCargarDatos() ?
                        <div className='col-sm-12 col-md-8 col-lg-7 py-3'>
                            <Loader />
                        </div>
                        :
                        <div className='bg-white col-sm-12 col-md-8 col-lg-7 border border-top-0 rounded-bottom mb-5 d-flex justify-content-center'>
                            {activeStep === steps.length ? (
                                <div className='px-4'>
                                    <div className="container-fluid">
                                        <div className="row m-0">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="card-body">
                                                    <div className="row m-0 d-flex justify-content-center text-center">
                                                        <div className="col-sm-12 col-md-12 col-lg-12 m-2 d-flex justify-content-center">
                                                            {(contDocumentosSubidos < cantDocumentosASubir || contFormasPago < cantFormasPagoASubir) && (
                                                                <Loader />
                                                            )}
                                                            {+id !== 0 && contDocumentosSubidos === cantDocumentosASubir && contFormasPago === cantFormasPagoASubir && (
                                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2 mb-4">
                                                                    <div className="card-body">
                                                                        <img src={imagenChecked} className="img-fluid m-2 py-2" width={70} alt="logo" />
                                                                        <h5 className="card-title pt-2 pb-3"> <strong>¡Listo!</strong> Cambios guardados exitosamente</h5>
                                                                        <Link className='btn fondo btn-lg m-1' to="/">Ir al Inicio</Link>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {+id === 0 && contDocumentosSubidos === cantDocumentosASubir && contFormasPago === listaFormasDePago.length && (
                                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2 mb-4">
                                                                    <div className="card-body">
                                                                        <img src={imagenChecked} className="img-fluid mx-2 my-3 " width={70} alt="logo" />
                                                                        <h5 className="card-title"> <strong>¡Listo!</strong> Formulario enviado</h5>
                                                                        <p className="card-text text-muted mt-3">
                                                                            Se enviaron datos del proveedor
                                                                        </p>
                                                                        <p className="card-text text-muted">
                                                                            Documentación adjunta
                                                                        </p>
                                                                        <p className="card-text text-muted">
                                                                            Formas de pago
                                                                        </p>
                                                                        <Link className='btn fondo btn-lg m-1' to="/">Ir al Inicio</Link>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {false && <Button onClick={handleReset}>Reiniciar</Button>}
                                </div>
                            ) : (
                                <div className='col-10'>
                                    <Typography className={classes.instructions}>

                                        {activeStep === 0 &&
                                            <div className='my-3'>
                                                <div className="row m-0 d-flex justify-content-start text-start">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 my-3 p-1 border-bottom">
                                                        <h2>Formulario de registro de proveedor</h2>
                                                        <p className='text-muted'>Complete la información requerida. (*) campos obligatorios</p>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 col-lg-4 my-3 d-flex flex-row text-end d-none">
                                                        <h5>{paisElegido?.nombre}</h5>
                                                        <img src={logoPais} className="img-fluid m-2 rounded shadow" height={30} width={40} alt="logo" />
                                                    </div>

                                                    {+id !== 0 &&
                                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                            <strong>Confirmaste tu cuenta exitosamente!</strong> Ahora puedes modificar tus datos, documento y formas de pago.
                                                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                        </div>

                                                    }
                                                    <div className="col-sm-12 col-md-12 col-lg-12 p-0">
                                                        <form onSubmit={handleSubmit}>

                                                            <div className='row m-0 d-flex justify-content-start'>
                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                                                                    <label className='mb-2' htmlFor='razon_social'>Razón Social *</label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='razon_social'
                                                                        name='razon_social'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.razon_social}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.razon_social &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.razon_social}</strong>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                                    <label className='mb-2' htmlFor='cuit'>CUIT *</label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='cuit'
                                                                        name='cuit'
                                                                        placeholder='sin puntos ni guiones'
                                                                        type='text'
                                                                        value={form.cuit}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />

                                                                    {errorsForm.cuit &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.cuit}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                                                                    <label className='mb-2' htmlFor='condicion_iva'>Condición IVA *</label>
                                                                    <select
                                                                        id="condicion_iva"
                                                                        className="form-select  my-1"
                                                                        name="condicion_iva"
                                                                        value={form.condicion_iva}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value='Seleccionar' >Seleccionar...</option>
                                                                        {listaCondicionesIva.map((act, index) => <option key={index} value={act} >{act}</option>)}
                                                                    </select>
                                                                    {errorsForm.condicion_iva &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.condicion_iva}</strong>
                                                                        </div>
                                                                    }

                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">

                                                                    <label className='mb-2' htmlFor='rubro_actividad_id'>Actividad/Rubro * </label>
                                                                    <select
                                                                        id="rubro_actividad_id"
                                                                        className="form-select  my-1"
                                                                        name="rubro_actividad_id"
                                                                        value={form.rubro_actividad_id}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value='Seleccionar' >Seleccionar...</option>
                                                                        {listaRubrosActividades.map((act, index) => <option key={index} value={act?.id} >{act?.afip_codigo}-{act?.afip_descripcion}</option>)}
                                                                    </select>
                                                                    {errorsForm.rubro_actividad_id &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.rubro_actividad_id}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                                                                    <label className='mb-2' htmlFor='ingresos_brutos'>Nro Inscripción de Ingresos Brutos * </label>
                                                                    <input
                                                                        className='form-control'
                                                                        name='ingresos_brutos'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.ingresos_brutos}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.ingresos_brutos &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.ingresos_brutos}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">

                                                                    <label className='mb-2' htmlFor='domicilio_comercial'>Domicilio comercial * </label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='domicilio_comercial'
                                                                        name='domicilio_comercial'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.domicilio_comercial}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.domicilio_comercial &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.domicilio_comercial}</strong>
                                                                        </div>
                                                                    }


                                                                </div>
                                                                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                                                                    <label className='mb-2' htmlFor='nombre_contacto'>Nombre de contacto *</label>
                                                                    <input
                                                                        className='form-control col-lg-2  my-1'
                                                                        id='nombre_contacto'
                                                                        name='nombre_contacto'
                                                                        placeholder=''
                                                                        type='text'
                                                                        value={form.nombre_contacto}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.nombre_contacto &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.nombre_contacto}</strong>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2 mb-3">
                                                                    <label className='mb-2' htmlFor='email'>Correo electrónico * </label>
                                                                    <input
                                                                        className='form-control'
                                                                        name='email'
                                                                        placeholder=''
                                                                        type='email'
                                                                        value={form.email}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errorsForm.email &&
                                                                        <div className="invalid-feedback d-block" role="alert">
                                                                            <strong>{errorsForm.email}</strong>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 col-lg-12 p-0">
                                                                    <div className='row m-0'>
                                                                        <div className="col-sm-12 col-md-6 col-lg-6">

                                                                            <label className='mb-2' htmlFor='cod_area'>Cod Área *</label>
                                                                            <input
                                                                                className='form-control col-lg-2  my-1'
                                                                                id='cod_area'
                                                                                name='cod_area'
                                                                                placeholder='sin puntos ni guiones'
                                                                                type='text'
                                                                                value={form.cod_area}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {errorsForm.cod_area &&
                                                                                <div className="invalid-feedback d-block" role="alert">
                                                                                    <strong>{errorsForm.cod_area}</strong>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 col-lg-6">

                                                                            <label className='mb-2' htmlFor='nro_telefono'>Nro Teléfono *</label>
                                                                            <input
                                                                                className='form-control col-lg-2  my-1'
                                                                                id='nro_telefono'
                                                                                name='nro_telefono'
                                                                                placeholder='sin puntos ni guiones'
                                                                                type='text'
                                                                                value={form.nro_telefono}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {errorsForm.nro_telefono &&
                                                                                <div className="invalid-feedback d-block" role="alert">
                                                                                    <strong>{errorsForm.nro_telefono}</strong>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2 py-2">
                                                                    <label className='mb-2' htmlFor='contribuyente_cm'> ¿Es contribuyente del convenio multilateral? </label> <br />
                                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                        <input type="radio" className="btn-check" checked={+form.contribuyente_cm === 1} name="contribuyente_cm" value={1} onChange={handleChange} id="contribuyente_cm_1" />
                                                                        <label className="btn btn-outline-primary" htmlFor="contribuyente_cm_1">SI</label>

                                                                        <input type="radio" className="btn-check" checked={+form.contribuyente_cm === 0} name="contribuyente_cm" value={0} onChange={handleChange} id="contribuyente_cm_2" />
                                                                        <label className="btn btn-outline-primary" htmlFor="contribuyente_cm_2">NO</label>
                                                                    </div>

                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2 py-2">
                                                                    <label className='mb-2' htmlFor='agente_percepcion_iva'> ¿Es Agente de percepción de IVA?</label> <br />
                                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                        <input type="radio" className="btn-check" checked={+form.agente_percepcion_iva === 1} name="agente_percepcion_iva" value={1} onChange={handleChange} id="agente_percepcion_iva_1" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iva_1">SI</label>

                                                                        <input type="radio" className="btn-check" checked={+form.agente_percepcion_iva === 0} name="agente_percepcion_iva" value={0} onChange={handleChange} id="agente_percepcion_iva_2" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iva_2">NO</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 col-lg-6 my-2  py-2">
                                                                    <label className='mb-2' htmlFor='agente_percepcion_iibb'>¿Es Agente de percepción de IIBB? </label> <br />
                                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                        <input type="radio" onClick={reinciarAgenteIbb()} className="btn-check" checked={+form.agente_percepcion_iibb === 1} name="agente_percepcion_iibb" value={1} onChange={handleChange} id="agente_percepcion_iibb_1" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iibb_1">SI</label>

                                                                        <input type="radio" className="btn-check" checked={+form.agente_percepcion_iibb === 0} name="agente_percepcion_iibb" value={0} onChange={handleChange} id="agente_percepcion_iibb_2" />
                                                                        <label className="btn btn-outline-primary" htmlFor="agente_percepcion_iibb_2">NO</label>
                                                                    </div>
                                                                    {+form.agente_percepcion_iibb === 1 &&
                                                                        <div className="col-sm-12 col-md-6 col-lg-12 my-2">

                                                                            <label htmlFor='agente_percepcion_iibb_descripcion'> Descripción de Jurisdicciones: </label>
                                                                            <input
                                                                                className='form-control my-1'
                                                                                name='agente_percepcion_iibb_descripcion'
                                                                                placeholder=''
                                                                                type='text'
                                                                                value={form.agente_percepcion_iibb_descripcion}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {errorsForm.agente_percepcion_iibb_descripcion &&
                                                                                <div className="invalid-feedback d-block" role="alert">
                                                                                    <strong>{errorsForm.agente_percepcion_iibb_descripcion}</strong>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>


                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 1 &&
                                            <DocumentacionProveedor
                                                proveedor={form}
                                                listaDoc={listaDocumentos}
                                                erroresDocs={listaErroresDocs}
                                                eventEmmiterLista={(listaDocumentos) => enviarRecuperarDocumentos(listaDocumentos)}
                                            />
                                        }
                                        {activeStep === 2 &&
                                            <FormFormaPagoComponent
                                                listaFP={listaFormasDePago}
                                                listaBancosTradicionales={listaBancosTradicionales}
                                                listaTiposCuentas={listaTipoDeCuentas}
                                                listaBancosDigitales={listaBancosDigitales}
                                                eventEmmiterLista={(listaFormaPagos) => enviarRecuperarFormasDePago(listaFormaPagos)}
                                            />
                                        }
                                        {activeStep === 3 &&
                                            <div>
                                                <VistaPreviaProveedor
                                                    proveedor={form}
                                                    pais={paisElegido}
                                                    listaDocumentos={listaDocumentos}
                                                    listaFormasDePago={listaFormasDePago}
                                                    listaRubrosActividades={listaRubrosActividades}
                                                />
                                            </div>
                                        }
                                    </Typography>

                                    <div className='mt-1 mb-3 py-1 px-0 border-top'>
                                        <div className='row m-0 d-flex justify-content-start py-2'>

                                            <div className="col-6 text-lg-start">
                                                {activeStep > 0 &&
                                                    <Button className='btn btn-lg m-1 btn-secondary'
                                                        onClick={handleBack}>
                                                        Atrás
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-6 text-lg-end pe-0">
                                                <Link className='btn btn-lg m-1 btn-light' to="/">Cancelar</Link>
                                                {activeStep === steps.length - 1 ?
                                                    <Button className='btn btn-lg m-1' disabled={deshabilitarBotonConfirmar} variant="contained" color="primary" onClick={() => enviarYConfirmar()}>
                                                        {textButtonConfirmar}
                                                    </Button>
                                                    :
                                                    <Button className='btn btn-lg m-1' variant="contained" color="primary" onClick={() => handleNext(activeStep)}>
                                                        Siguiente
                                                    </Button>
                                                }
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {dataModal &&
                        <div className="col-12 text-lg-end pe-0">
                            < ModalComponent
                                dataModal={dataModal}
                            />
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default FormProveedorComponent;


