import React, { useState } from 'react'
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import logo from '../../../assets/images/el_cuatro_horizontal.png';

const VistaProveedorPdf = (props) => {
    const [proveedor] = useState(props.proveedor);
    
    const tranformarPropiedad = (key) => {
        let nuevaKey = '';
        switch (key.toString().toUpperCase()) {
            case 'RAZON_SOCIAL':
                return 'RAZÓN SOCIAL'
            case 'RUBRO_ACTIVIDAD_ID':
                    return 'RUBRO/ACTIVIDAD'
            case 'NRO_TELEFONO':
                return 'NRO TELÉFONO'
            case 'CONVENIO_CM':
                return 'CONVENIO MULTILATERAL'
            case 'IIBB':
                return 'CONSTANCIA DE INSC. INGRESOS BRUTOS'
            case 'AFIP':
                return 'CONSTANCIA DE INSC. AFIP'
            case 'EMAIL':
                return 'CORREO ELECTRÓNICO'
            case 'INGRESOS_BRUTOS':
                return 'NRO INSCRIPCIÓN INGRESOS BRUTOS';
            case 'CREATED_AT':
                return 'FECHA DE ALTA'
            case 'UPDATED_AT':
                return 'FECHA DE ACTUALIZACIÓN'
            case 'AGENTE_PERCEPCION_IIBB_DESCRIPCION':
                return 'DESCRIPCIÓN DE JURIDICCIONES'
            case 'AGENTE_PERCEPCION_IVA':
                return 'AGENTE PERCEPCIÓN IVA'
            case 'AGENTE_PERCEPCION_IIBB':
                return 'AGENTE PERCEPCIÓN IIBB'
            case 'CONDICION_IVA':
                return 'CONDICIÓN IVA'
            case 'PAIS_ID':
                return 'PAÍS'
            default:
                nuevaKey = key.toString().split("_").join(' ');
                nuevaKey = nuevaKey.trim();
                return nuevaKey.toString().toUpperCase();
        }
        
    }

    const tranformarValue = (key, value) => {
        if (value == null || value === '') {
            return ' - ';
        }

        if (key.toUpperCase() === 'PAIS_ID') {
            return proveedor.pais?.nombre;
        }
        if (key.toUpperCase() === 'RUBRO_ACTIVIDAD_ID') {
            return proveedor.rubro_actividad?.afip_codigo +'-'+proveedor.rubro_actividad?.afip_descripcion;
        }

        let keysFechas = ['CREATED_AT', 'UPDATED_AT'];
        if (typeof value === 'object') {
            return value?.nombre;
        }
        if (keysFechas.includes(key.toUpperCase())) {
            let fecha = new Date(value);
            return fecha.toLocaleDateString();
        }
        if ([0, 1].includes(+value)) {
            return value === 0 ? 'NO' : 'SI';
        }
        return value;
    }

    const [listaCamposProveedorOcultar] = useState(true ? ['id', 'pais', 'rubro_actividad', 'codigo_confirmacion', 'confirmado','forma_pagos'] : ['id', 'pais', 'codigo_confirmacion', 'confirmado', 'created_at', 'updated_at']);
    const validarCamposAMostrar = (campo) => {
        return listaCamposProveedorOcultar.find(e => e === campo.toLowerCase());
    }

    const styleText = {
        fontSize: 11,
        padding: '4px'
    }

    const styleTitulo = {
        fontSize: 12,
        fontWeight: 'bold',
    }

    return (
        <Document>
            <Page size="A4">
                <View style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "center",

                    width: '100%',
                    padding: 10,
                }}>
                    <View style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: '#212429',
                        width: '100%'
                    }}>
                        <Image src={logo} style={{
                            width: 200
                        }} alt="logo" />
                    </View>

                    <View style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "left",
                        backgroundColor: '#212429',
                        color: 'white',
                        width: '100%',
                        paddingHorizontal: 10,
                        paddingVertical: 0,
                        border: "1px solid #212429"
                    }}>
                        <Text style={{ styleTitulo }} >Proveedor</Text>
                    </View>

                    <View style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "center",
                        width: '100%',
                        paddingHorizontal: 10,
                        paddingVertical: 0,
                        marginVertical: 0

                    }}>
                        {proveedor &&
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                width: '100%',
                            }}>

                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    textAlign: 'left',
                                    alignItems: "left",
                                    paddingRight: 15,
                                    width: '50%'

                                }} >
                                    {Object.entries(proveedor).slice(0, Math.trunc(Object.entries(proveedor).length / 2) + 1).map(([key, value]) =>
                                        (!validarCamposAMostrar(key) && <Text style={styleText} key={key}>{tranformarPropiedad(key)}: {tranformarValue(key, value)} </Text>)
                                    )}
                                </View>

                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    textAlign: 'left',
                                    alignItems: "left",
                                    width: '50%'
                                }}  >
                                    {Object.entries(proveedor).slice(Math.trunc(Object.entries(proveedor).length / 2) + 1, Object.entries(proveedor).length).map(([key, value]) =>
                                        (!validarCamposAMostrar(key) && <Text style={styleText} key={key}>{tranformarPropiedad(key)}:{tranformarValue(key, value)} </Text>)
                                    )}
                                </View>
                            </View>
                        }
                    </View>
                    {/* seccion de formas de pago*/}
                    <View style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "left",
                        paddingHorizontal: 10,
                        paddingVertical: 3,
                        width: '100%'
                    }}>
                        <View style={{
                            backgroundColor: '#212429',
                            color: 'white',
                            paddingVertical: 3
                        }}>
                            <Text style={{ styleTitulo }} >Formas de Pago</Text>
                        </View>

                        {props?.listaFormaPagos?.length === 0 &&
                            <Text style={{ fontWeight: 'bold', paddingVertical: 5 }}>No se agrego ninguna forma de pago</Text>
                        }
                        {props.listaFormaPagosTransferencia.length > 0 && <Text style={{ fontSize: 12, fontWeight: 'bold', paddingVertical: 3 }}>Transferencias</Text>}

                        {props.listaFormaPagosTransferencia.length > 0 && props.listaFormaPagosTransferencia?.map((mp, index) =>
                            <View key={index} style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                marginVertical: 4,
                                width: '100%',
                                border: "1px solid black"
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    textAlign: 'left',
                                    alignItems: "left",
                                    width: '50%'
                                }} >
                                    <Text style={styleText}>Tipo de pago:  {mp.pago_tipo}</Text>
                                    <Text style={styleText}>Titular: {mp.titular_cuenta}</Text>
                                    <Text style={styleText}>Tipo de Banco: {mp.banco_tipo}</Text>
                                    <Text style={styleText}> {mp.banco_tipo === 'TRADICIONAL' ? 'CBU' : 'CBU/CVU'}: {mp.cbu_cvu}</Text>
                                    <Text style={styleText}>Alias: {mp.alias}</Text>
                                </View>

                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    textAlign: 'left',
                                    alignItems: "left",
                                    width: '50%'
                                }}  >
                                    <Text style={styleText}>Nombre de la Entidad: {mp.banco_nombre}</Text>
                                    <Text style={styleText}> Nro Cuenta: {mp.nro_cuenta}</Text>
                                    {mp.banco_tipo === 'TRADICIONAL' && mp.cuenta_tipo && <Text style={styleText}>Tipo de Cuenta: {mp.cuenta_tipo}</Text>}
                                    {mp.banco_tipo === 'TRADICIONAL' && mp.cuenta_tipo && mp.cuenta_tipo.includes('OTRO') && <Text style={styleText}>Otro Tipo de Cuenta: {mp.cuenta_tipo_otro}</Text>}
                                    <Text style={styleText}>CUIL/CUIT: {mp.cuil_cuit}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                    {props.listaFormaPagosContado.length > 0 && <View style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "left",
                        width: '100%',
                        paddingHorizontal: 10
                    }}>


                        {props?.listaFormaPagosContado?.length > 0 && props.listaFormaPagosContado?.map((mp, index) =>
                            <View key={index} style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                width: '100%',
                                padding: 0,
                                border: "1px solid black"
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    textAlign: 'left',
                                    alignItems: "left",
                                    width: '100%'
                                }} >
                                    <Text style={styleText}>Tipo de pago:  {mp.pago_tipo}</Text>
                                  
                                </View>
                            </View>
                        )}
                    </View>}

                    {props.listaFormaPagosCheques.length > 0 && <View style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "left",
                        width: '100%',
                        paddingHorizontal: 10
                    }}>

                        {props.listaFormaPagosCheques.length > 0 && <Text style={{ fontSize: 11, fontWeight: 'bold', paddingVertical: 5 }}>Cheques</Text>}

                        {props?.listaFormaPagosCheques?.length > 0 && props.listaFormaPagosCheques?.map((mp, index) =>
                            <View key={index} style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                width: '100%',
                                padding: 0,
                                border: "1px solid black"
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    textAlign: 'left',
                                    alignItems: "left",
                                    width: '100%'
                                }} >
                                    <Text style={styleText}>Tipo de pago:  {mp.pago_tipo}</Text>
                                    <Text style={styleText}>A la orden de: {mp.cheque_orden}</Text>
                                    <Text style={styleText}>Concepto:  {mp.cheque_concepto}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                    }

                    <View style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "left",
                        padding: 10,
                        width: '100%'
                    }}>
                        <View style={{
                            backgroundColor: '#212429',
                            color: 'white',
                            paddingVertical: 3
                        }}>
                            <Text style={{ styleTitulo }} >Documentación adjunta</Text>
                        </View>

                        {props?.listaDocumentacionProveedor?.length === 0 &&
                            <Text>No se adjunto ninguna documentación</Text>
                        }
                        {props.listaDocumentacionProveedor?.length > 0 && props.listaDocumentacionProveedor?.map((value, index) =>
                            <View key={index} style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                width: '100%'
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    textAlign: 'left',
                                    alignItems: "left",
                                    width: '100%'
                                }} >
                                    <Text key={index} style={styleText}>* {tranformarPropiedad(value.categoria)}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </Page>
        </Document>

    )
}

export default VistaProveedorPdf