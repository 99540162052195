import React from 'react'
import { useState } from 'react';
import { Fragment } from 'react'

const DataProveedorComponent = (props) => {
    const { proveedor } = props;
    const { pais } = props;
    const { rubroActividad } = props;

    const tranformarPropiedad = (key) => {
        let nuevaKey = '';
        switch (key.toString().toUpperCase()) {
            case 'RAZON_SOCIAL':
                return 'RAZÓN SOCIAL'
            case 'INGRESOS_BRUTOS':
                return 'NRO INSCRIPCIÓN INGRESOS BRUTOS';
            case 'NRO_TELEFONO':
                return 'NRO TELÉFONO'
            case 'EMAIL':
                return 'CORREO ELECTRÓNICO'
            case 'CREATED_AT':
                return 'FECHA DE ALTA'
            case 'UPDATED_AT':
                return 'FECHA DE ACTUALIZACIÓN'
            case 'AGENTE_PERCEPCION_IIBB_DESCRIPCION':
                return 'DESCRIPCIÓN DE JURIDICCIONES'
            case 'AGENTE_PERCEPCION_IVA':
                return 'AGENTE PERCEPCIÓN IVA'
            case 'AGENTE_PERCEPCION_IIBB':
                return 'AGENTE PERCEPCIÓN IIBB'
            case 'CONDICION_IVA':
                return 'CONDICIÓN IVA'
            case 'PAIS_ID':
                return 'PAÍS'
            case 'RUBRO_ACTIVIDAD_ID':
                return 'RUBRO/ACTIVIDAD';
            default:
                nuevaKey = key.toString().split("_").join(' ');
                nuevaKey = nuevaKey.trim();
                return nuevaKey.toString().toUpperCase();
        }
    }

    const tranformarValue = (key, value) => {
        if (value == null || value === '') {
            return ' --- ';
        }

        if (key.toUpperCase() === 'PAIS_ID') {
            return pais?.nombre;
        }
        if (key.toUpperCase() === 'RUBRO_ACTIVIDAD_ID') {
            return rubroActividad?.afip_codigo + '-' + rubroActividad?.afip_descripcion;
        }

        let keysFechas = ['CREATED_AT', 'UPDATED_AT'];
        if (typeof value === 'object') {
            return value?.nombre;
        }
        if (keysFechas.includes(key.toUpperCase())) {
            let fecha = new Date(value);
            return fecha.toLocaleDateString();
        }
        if ([0, 1].includes(+value)) {
            return value === 0 ? 'NO' : 'SI';
        }

        return value;

    }

    const [listaCamposProveedorOcular] = useState(props.mostrarFechas ? ['id', 'pais', 'rubro_actividad', 'codigo_confirmacion', 'confirmado'] : ['id', 'pais', 'rubro_actividad', 'codigo_confirmacion', 'confirmado', 'created_at', 'updated_at']);
    const validarCamposAMostrar = (campo) => {
        return listaCamposProveedorOcular.find(e => e === campo.toLowerCase());
    }
    const [listaCamposClassMitad] = useState(['cuit', 'condicion_iva', 'cod_area', 'nro_telefono', 'contribuyente_cm', 'agente_percepcion_iva', 'agente_percepcion_iibb']);

    const cambiarOrdenAtributos = (proveedor) => {
        const nuevoObjeto = {
            razon_social: proveedor.razon_social,
            cuit: proveedor.cuit,
            condicion_iva: proveedor.condicion_iva,
            rubro_actividad_id: proveedor.rubro_actividad_id,
            ingresos_brutos: proveedor.ingresos_brutos,
            domicilio_comercial: proveedor.domicilio_comercial,
            nombre_contacto: proveedor.nombre_contacto,
            email: proveedor.email,
            cod_area: proveedor.cod_area,
            nro_telefono: proveedor.nro_telefono,
            contribuyente_cm: proveedor.contribuyente_cm,
            agente_percepcion_iva: proveedor.agente_percepcion_iva,
            agente_percepcion_iibb: proveedor.agente_percepcion_iibb,
            agente_percepcion_iibb_descripcion: proveedor.agente_percepcion_iibb_descripcion,
        };
        return nuevoObjeto;
    };
    
    const getClassCampos = (key) => {
        return listaCamposClassMitad.find(e => e === key) ? 'col-md-6 col-lg-6' : 'col-md-12 col-lg-12';
    }

    return (
        <Fragment>
            <div>
                <div className='row d-flex justify-content-start'>                  
                    {Object.entries(cambiarOrdenAtributos(proveedor)).map(([key, value]) =>
                        (!validarCamposAMostrar(key) &&
                            <div className={`col-sm-5 py-2 border-bottom ${getClassCampos(key)}`}>
                                <p className='my-2' key={key}><strong>{tranformarPropiedad(key)}:</strong> {tranformarValue(key, value)} </p>
                            </div>
                        )
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default DataProveedorComponent