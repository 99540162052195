import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";

const Profile = () => {

  const { user } = useAuth0();

  return (
    <div className='container bg-white mb-0 mt-0 pt-4 pb-2'>
      <div className='px-3 pt-3 text-start  border-bottom'>
        <h1>Sistema de Proveedores</h1>
        <p className='text-muted'>Este sistema te permitirá gestionar de manera más eficiente los proveedores.</p>
      </div>
      <div className='row m-0 px-3 py-3 d-flex justify-content-start'>
          <div className="col-sm-12 col-md-12 col-lg-1 my-1">
            {user?.picture && <img className="rounded-circle img-fluid m-0" src={user?.picture} alt={user?.name} style={{ width: '100px' }} />}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-10 my-1 text-start">
            <h3>Bienvenido/a {user.name}</h3>
            <p>{user.email}</p>
          </div>
          <div className="col-12 py-4  text-start">
            <LogoutButton />
          </div>
      </div>
    </div>

  );
};

export default Profile;